import React from 'react';
import logo from '../../assets/img/Misaal_logo.png';
import img1 from '../../assets/img/magic_bus_logo.png';
import img2 from '../../assets/img/tripod_logo.png';
import img3 from '../../assets/img/meritnation_logo.png';
import ananya from '../../assets/img/img_Ms_Ananya.jpg';
import ahana from '../../assets/img/img_Ms_Ahana.jpg';
import media_article_hed from '../../assets/img/higher_education_digest.jpg';
import media_article_crs from '../../assets/img/crs_mandate.jpg';
import media_article_crs_facebook from '../../assets/img/crs_mandate_facebook.jpg';

const MediaArticles = () => {
    return (
        <>
            <br />
            <div className="container-fluid lgbg pt-5 pr-0 pb-5 pl-0 ">
                <br />
                <div className="container">
                    <h1>Media Articles</h1>
                    <br />

                    <div className="row ml-0">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center h-100 pl-0 my-2 whiteBg">
                            <div className="row categories-inner media-article">
                                <a href="https://www.highereducationdigest.com/youth-of-mumbai-host-first-ever-virtual-talent-hunt-for-gen-z-to-make-a-difference-to-lives-of-the-marginalized-community-post-the-covid-19-pandemic/" target="_blank" rel="noopener noreferrer">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center pl-0" style={{ margin: '16px 0 0 0' }}>
                                        <h6>Higher Education Digest<div>July 2, 2020</div></h6>
                                        <hr /></div>
                                    <img alt="Support Partners" title="Online Learning Partner" src={media_article_hed} style={{ width: '100%', margin: '1.4rem 0' }} />
                                    <button className="border-0 bgPrimary text-white rounded-pill font-weight-bold py-1 px-3 ">Know More</button>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center my-2 h-100 pl-0 whiteBg">
                            <div className="row categories-inner text-center media-article">
                                <a href="https://www.csrmandate.org/virtual-talent-hunt-for-gen-z-to-make-a-difference-to-marginalised-communities-post-covid-19/" target="_blank" rel="noopener noreferrer">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center pl-0" style={{ margin: '16px 0 0 0' }}>
                                        <h6>CRS Mandate<div>July 3, 2020</div></h6>
                                        <hr /></div><img alt="Support Partners" title="Online Learning Partner" src={media_article_crs} style={{ width: '100%', margin: '1.4rem 0' }} />
                                    <button className="border-0 bgPrimary text-white rounded-pill font-weight-bold py-1 px-3 ">Know More</button>
                                </a>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center h-100 pl-0 my-2 whiteBg">
                            <div className="row categories-inner media-article">
                                <a href="https://www.facebook.com/CSRMandate/posts/misaal-concert-a-virtual-talent-platform-for-youth-globally-is-aimed-at-touching/2557527974510518/" target="_blank" rel="noopener noreferrer">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center pl-0" style={{ margin: '16px 0 0 0' }}>
                                        <h6>CRS Mandate (Facebook)<div>July 3, 2020</div></h6>
                                        <hr /></div>
                                    <img alt="Support Partners" title="Online Learning Partner" src={media_article_crs_facebook} style={{ width: '100%', margin: '1.4rem 0' }} />
                                    <button className="border-0 bgPrimary text-white rounded-pill font-weight-bold py-1 px-3 ">Know More</button>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="container-fluid pl-0 pr-0">
                <br />
                <div className="container" >
                    <h3 className="text-center" style={{ fontStyle: 'italic' }}>Let us be a </h3>
                    <p className="text-center"><img src={logo} alt="Misaal - Young India Inspires" title="Misaal - Young India Inspires" className="let-us-logo" /></p>
                </div>
                <h5 className="text-center" style={{ background: '#fecc2f', padding: '1rem', color: '#5b085c', margin: '0' }}>"Help others achieve their dreams and you will achieve yours"<br />- Les Brown</h5>
            </div>
        </>
    );
};

export default MediaArticles;
