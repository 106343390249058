import React from 'react';
import covid from '../../assets/img/img_Covid19.png';
import img1 from '../../assets/img/magic_bus_logo.png';

const HowItWorks = () => {
  return (
    <>    
    <div className="container-fluid"> 
    <br /><br /> 
    <div className="container"> 
      <h1>Donate and build a <strong>bright future for others!</strong></h1>
      <br /> <br />    
        <div className="row ml-0">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="row">
              <p>This is an appeal to serve our fellow Indians. You can promote <strong>Misaal</strong>'s initiative in any way that suits you best</p>
              <ul>
                <li>Participate in your individual capacity</li>
                <li>Participate as a support partner and converge with your CSR offerings.</li> 
                <li>Participate for a cause and converge with your CSR offerings.</li> 
                <li>Matching Donations.</li> 
                <li>Become partner for the event </li>
              </ul>
              <p>Our NGO partner <strong>Magic Bus</strong> helps utilise all proceeds of the show to support the affected people who are struggling to get even a meal a day.</p>
              <p>Show your generosity, donate directly to <strong>Magic Bus</strong>.</p>
            </div>
            <div className="row">
              <a className="border-0 bgPrimary text-white rounded-pill font-weight-bold button-padding" href="https://donation.magicbus.org/campaign/misaal" target="_blank"  rel="noopener noreferrer">DONATE NOW </a>              
            </div>            
            <br />
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
            <div className="row donate-right">
              <div className="greyBg">
                  <img src={covid} alt="" className="covid-img" />
                  <h4>COVID19 Impact</h4>
                  <ul>
                    <li>Nearly 25% of the workforce has become unemployed (Centre for Monitoring Economy).</li>
                    <li>Health crisis affected millions.</li>
                    <li>Significant impact on mental health.</li>
                    <li>Help us fight unemployment, hunger, and mental issues caused by the Covid19 crisis.</li>
                  </ul>
              </div>
              <br />              
            </div>
          </div>
        </div>
    </div>
    </div> 
    <div className="container-fluid">
      <div className="container">
      <h1>Our NGO Partner - <strong>Magic Bus</strong></h1>
        <br />
        <p className="text-center"><img src={img1} alt="Magic Bus" title="Magic Bus" style={{width: '203px'}} /></p>
        <p style={{textAlign: 'justify'}}>Magic Bus, founded in 1999 by Matthew Spacie, has transformed the lives of over one million children and young people living in poverty, taking them from a childhood full of challenges to a life with meaningful livelihoods. We are one of the largest poverty alleviation programmes in India, impacting 3,75,000 children and young people in 22 states and 80 districts of India, in this year alone. We deliver our sports activity-based sessions in close to 2000 communities and 1000 schools in urban slums and remote rural areas. Since the Livelihood programme began in 2015, we have 53 Livelihood centres through which 30,000 youth have been trained and more than 70% placed in jobs in the organised sector with an average salary of INR 10,287. Magic Bus also works in Nepal, Bangladesh, and Myanmar with 6000 children, in 40 communities and 31 schools. We have networking and fundraising offices in US, UK, Singapore and Germany.</p>
        <p style={{textAlign: 'justify'}}>In the wake of the COVID- 19 pandemic, Magic Bus has been working in crisis zones across rural and urban India and has provided dry ration kits and 1.9 crores meals to more than 300,000 people across the country.</p>
        <h6 className="text-center">The proceeds of the Talent Hunt organised by Misaal will go to Magic Bus</h6>
        <div className="row justify-content-center align-items-center my-1">
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 text-center my-3">
              <a href="https://www.magicbus.org/" target="_blank" rel="noopener noreferrer" className="w-100" style={{ color: '#e9208b'}}>Visit MagicBus Website</a>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-4 col-sm-12 col-12 text-center my-3">
              <a className="border-0 bgPrimary text-white rounded-pill font-weight-bold button-padding" href="https://donation.magicbus.org/campaign/misaal" target="_blank"  rel="noopener noreferrer">DONATE NOW</a>
            </div>
        </div>
        <br />
      </div>
    </div>    
    </>
  );
};

export default HowItWorks;
