import React, { useEffect, useContext, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

import axios from 'axios';

import { AppContext } from '../ContextProvider';

import Categories from '../categories/categories';
import Showcase from '../../common/Showcase/Showcase';
import About from '../../common/About/About';
import HowItWorks from '../../common/HowItWorks/HowItWorks';
import RegisterModal from '../../common/RegisterModal/RegisterModal';
import Partners from '../../common/Partners/Partners';
import MediaArticles from '../../common/MediaArticles/MediaArticles';

const HomePage = () => {
  const {
    saveCategories,
    categories = [],
    registerModal: { show },
    toggleRegisterModal
  } = useContext(AppContext);

  const [successAlert, toggleSuccessAlert] = useState(false);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}api/participants/categories`);
        // console.log(response.data);
        saveCategories(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (categories && categories.length === 0) fetchCategories();
  }, []);

  const handleRegisterUploadSuccess = () => {
    toggleRegisterModal();
    toggleSuccessAlert(true);
  };

  return (
    <>
      {show && <RegisterModal onSuccess={handleRegisterUploadSuccess} />}
      <div id="home">
        <Showcase />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="talentHunt">
        <Categories />
      </div>
      <div id="donate">
        <HowItWorks />
      </div>
      <div id="partners">
        <Partners />
      </div>
      <div id='mediaArticles'>
        <MediaArticles />
      </div>
      {successAlert && (
        <Modal show={successAlert} className="border-0" backdrop="static">
          <Modal.Body className="text-center pt-5">
            <p>
              Thank you. Your entry has been registered. <br />
              Please quote your phone number and the category for all future communication with us.
            </p>
          </Modal.Body>

          <Modal.Footer className="d-fflex justify-content-center align-items-center py-0 pb-3 border-0">
            <Button variant="secondary" size="sm" onClick={() => toggleSuccessAlert(false)} className="bg-success px-4">
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default HomePage;
