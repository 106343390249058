import React from 'react';

const Terms = () => {
  return (
    <>
        <br />
        <div className="container">
          <h1 className="th-title">Terms of <strong>Access and Use</strong></h1>
          <br />
          <div className="row p-1 text-center" style={{fontStyle: 'italic'}}>
                  This is an event organized by the youth for a social cause with minimal cost. So we apologise and request you to excuse us for any mistake or issue.
                </div><br />
          <h4>Terms of Access and Use</h4>

          <ul className="ul-list">
<li>This Terms of Use is an electronic contract and does not require any physical, electronic or digital signature.
</li><li>This Terms of Use located at the website and/ or mobile applications ("Platform") is a legally binding agreement between ("Misaal" or "we" or "us" or "our") and the guest users or registered users of the Platform (shall hereinafter be referred to as "you" or "your" or "yourself" or the "User"). We understand that the registered users and the guest users could involve students, minor, their parents or guardians; however, we require you to be fully aware that all participation and involvement by students or minors should be under the supervision, consent and acknowledgement of their parents or guardians, including consenting to these Terms of Use and other policies of the Platform.
</li><li>The Terms of Use provides the terms and conditions on which Misaal offers you the access to the Platform for use of any services or facilities thereto in connection with the Misaal Talent Hunt ("Facilities").
</li><li>The Terms of Use, the privacy policy and any other applicable polices (the "Policies"), as updated from time to time, govern your access and use of our Platform and Facilities. This Terms of Use will be effective upon your acceptance of the same (directly or indirectly in electronic form or by means of an electronic record) and will govern the relationship and transactions between the users and us for the access or use of the Facilities offered through the Platform.
</li><li>Please read the terms and conditions of the Terms of Use fully before using or registering on the Platform or accessing any material, information or availing Facilities through the Platform. By downloading, installing, or otherwise accessing or using our Platform or Facilities (whether in whole or in part), you agree that you have read, understood, and agree to be bound by the Terms of Use. Further, the participant’s act of voluntarily participating in the Program in the manner as detailed below shall be deemed to be the participant’s and/or the parent/ guardian of the participant’s acceptance to the Terms of Use and Policies of Misaal,
</li><li>By agreeing to the Terms of Use and accessing the Facilities, you represent that you are either at least 18 years of age and have complete and unrestricted right and authority to accept this Terms of Use and abide by the terms and conditions, or that you are a minor or a student and that your parents or guardians have reviewed the Terms of Use and have acknowledged and agreed to the Terms of Use on your behalf. Further, with respect to minors, we recommend that the parents or guardians review these Terms of Use, and by using the Platform, you are representing that you have given us your consent to allow any of your minor dependents to access and use our Facilities in your capacity as parents/ legal guardian.
</li><li>Upon your consenting to the Terms of Use and the Policies of the Company (which are incorporated by reference into this Terms of Use), we hereby grant you a limited, personal, revocable, non-assignable and non-exclusive license to use our Facilities. This license is for the sole purpose of enabling you to access and use the Facilities in the manner expressly permitted by this Terms of Use and the Policies.
</li><li>If you do not agree with the Terms of Use or any other Policies, please do not use the Platform or avail any Facilities being offered therein. We reserve the right to change the terms and conditions of this Terms of Use or other Policies from time to time. The users agree that the amended terms and conditions of this Terms of Use shall be effective and binding on the on the date posted on the Platform.
</li></ul>
<h4>Access to the Facilities</h4>
<ul className="ul-list">
<li>It is your responsibility to ensure that the details provided by you are accurate and treat such information as confidential. Based on the information provided by you to us, you may be provided with a login identification or unique identification (such as a username and password, or a guest identification or any other identifier). You are solely responsible for all activities that occur under your login identification/ unique identification and must notify us immediately of any unauthorized use or other security breach relating to your login identification/ unique identification. Further, we may suspend or terminate the use of the Facilities by any user for violation of any of the terms and conditions of the Terms of Use or any of the Policies.

</li><li>We reserve the right to block, disable or delete any user/ login identification/ unique identification, at any time, if in our opinion, you have failed to comply with any of the provisions of the Terms of Use or any other Policies, or if any details you provide for the purposes of registering as a user of the Facilities prove to be false or based on misrepresentation.

</li><li>You consent to any collection, use or disclosure of personal information or personally identifiable data required to provide you with access to the Facilities, as per the Privacy Policy.

</li><li>The user is responsible for providing and maintaining current email, mobile phone, residential address (current/ temporary and permanent), and other account information with us, in the manner required by us. The user further warrants that information provided to us shall be correct and is bound to inform us promptly, if any change occurs in the information provided earlier including but not limited to mobile phone number, residential address, etc.

</li><li>Currently, only individuals are eligible to register for use of the Facilities and access the Facilities. Further, the user agrees and acknowledges that we retain and reserve the sole discretion to make determinations of eligibility, access and use, and that we also reserve the right to change any or all of the eligibility, access and use requirements at any point in time as and when deemed fit and necessary.
</li></ul>
<h4>Program</h4>
<ul className="ul-list">
<li>This clause applies to all participants moving into the advanced round of Misaal Talent Hunt: The participating users agree and acknowledge that, given the efforts that are required on part of both Misaal and participating users with respect to the Program, the participation of the participating users will be exclusive to the Program. During the course of the Program, the participating users will not engage or participate in any other competing program or similar program or any talent search program/ competition, without prior written approval of Misaal. [Misaal believes that this is essential to ensure that the participating users are dedicated to the requirements of the Program.]
</li><li>The user confirms and acknowledges that the user will be responsible for any copyright issue/ concerns/ objections about the song/ lyrics/ music/ karaoke/ tracks or any other features that the user will be using on the Platform, and that Misaal will not be liable or held responsible in any manner whatsoever.
</li><li>The participating users are required to submit their videos/ audios as per the recording guidelines provide online on the Platform, along with the requisite personal details. The participating users are also responsible for the quality of their recording and adhering to the rules specified by Misaal. Misaal reserves the right to disqualify any performer if it is felt that it is inappropriate to be on the Platform or as part of Misaal Talent Hunt . Removal of clothing, abusive language (jumping from the stage, damaging equipment in case of live audition), obscenity, derogatory or communal remarks or statements, vulgarity, etc. will result in immediate disqualification.
</li><li>The judge's decision on the competition related matters is final. Any contestant who attempts to reach judges or their staff for any manipulation may be disqualified from the competition.
</li><li>The user further acknowledges that Misaal will have no responsibility towards any copyrights or any other issues that may be sung, played or danced to, or otherwise provided by the user onto the Platform. Further, the user will indemnify Misaal from any copyright issue of the song/ lyrics/ music/ karaoke/ tracks or any other features that the user will be using to participate in Misaal Talent Hunt online or onsite; and all such responsibility with respect to the copyright issues or any objections will be that of the user.
</li><li>All the material submitted to, and produced by during Misaal Talent Hunt, including the participation entries provided by the user will be the exclusive property of Misaal, and may be used by us in the manner we deem fit. In particular, the participating user shall grant to Misaal, its successors, licensees and assigns, without charge or other compensation, the irrevocable exclusive right, in perpetuity and throughout the universe, to film, tape and/or photograph, perform, record, exhibit, display, edit, distribute, sell and otherwise use the participating users’ appearance, name, similarity, voice, vocals, conversations, sounds, signature, biographical data, and any information or materials provided by participating users to us in connection with and or ancillary to the Misaal Talent Hunt  in any and all. Misaal shall also have the unrestricted right to edit the content and text of Misaal Talent Hunt as well as all film, tape, photography, recordings and user appearances thereon, in any manner or form.
</li><li>The user agrees that the user’s selection to enter into and participate in Misaal Talent Hunt is within Misaal’s sole discretion. In the event a user is selected, the user agrees that the selection as a contestant for Misaal Talent Hunt  is within Misaal’s sole discretion and that Misaal is not obligated to select any particular user.
</li><li>The user acknowledges that Misaal reserves the right, exercisable at any time in its sole discretion, to disqualify a participating user from Misaal Talent Hunt , including on account of being untruthful, inaccurate, providing misleading personal information, failing to abide by rules or regulations of Misaal Talent Hunt , or for any other reason. If a participating user is selected to advance beyond the preliminary level, the user agrees that Misaal is not obligated to have such user appear on, or to telecast any appearance on Misaal Talent Hunt  and may disqualify the user at any time in its sole discretion.
</li><li>Misaal reserves the right to remove from Misaal Talent Hunt , any person if Misaal determines, in its sole discretion, that such person is sufficiently connected with Misaal Talent Hunt  and that such person’s participation in it could create the appearance of impropriety. Conversely, Misaal reserves the right to allow any person to remain part of Misaal Talent Hunt  who may have a connection with it, if in its sole discretion, Misaal determines that such person’s involvement in Misaal Talent Hunt  does not and will not affect the integrity of the it in any manner whatsoever.
</li><li>Further, if the users perform any original music for/ on Misaal Talent Hunt , including musical arrangements, titles, lyrics, music and all other elements of such songs or submit any original photographs, or original audio and/or video recordings to Misaal, the users agree and acknowledge that the in addition to the rights of the user to such music, lyrics, photos and recordings, the user shall also grant to Misaal, without charge, the rights necessary to use such music, lyrics, photos and recordings on and in connection with Misaal Talent Hunt  and the rights required to exploit it and the ancillary rights therein (including, without limitation, in advertisements, promotions and publicity), in any and all media, or for any purpose, throughout the universe in perpetuity. In this regard, the user will waive any rights and claims against Misaal with respect to their use and exploitation of such music, lyrics, photos and recordings provided by the users.
</li><li>The user agrees to follow all of Misaal’s rules, directions and instructions in all matters relating to Misaal Talent Hunt , which rules are subject to change at our sole discretion. The user acknowledges and agrees to accept any and all decisions of Misaal on all matters, including, without limitation, discretionary matters (including, but not limited to, the interview and audition process and contestant selection) as final and not subject to challenge, and acknowledge that the interests of Misaal Talent Hunt  shall override those of any participants or users.
</li><li>The user agrees and acknowledges that the user’s audition or participation on Misaal Talent Hunt / Platform shall include the user’s own act or performance, which the user created, and further represents and warrants that the user is in a state of physical, emotional, psychological, and mental health capable of performing my act for Misaal Talent Hunt  and otherwise participating in it. The user shall immediately/ effectively notify us of any such change in physical, emotional, psychological, or mental conditions of the user that might affect the user or the user’s performance on Misaal Talent Hunt . We may, in our sole discretion, refuse to allow a user to participate in the if we have reasons to believe that the user is not capable of performing the act.
</li><li>Parental supervision/ guardian supervision is recommended in the user’s audition or participation on Misaal Talent Hunt , and we prefer that all participation is undertaking with the consent of parents/ guardians.
</li><li>Further, if the users perform any original music for/ on Misaal Talent Hunt , including musical arrangements, titles, lyrics, music and all other elements of such songs or submit any original photographs, or original audio and/or video recordings to Misaal, the users agree and acknowledge that the in addition to the rights of the user to such music, lyrics, photos and recordings, the user shall also grant to Misaal, without charge, the rights necessary to use such music, lyrics, photos and recordings on and in connection with Misaal Talent Hunt  and the rights required to exploit it and the ancillary rights therein (including, without limitation, in advertisements, promotions and publicity), in any and all media, or for any purpose, throughout the universe in perpetuity. In this regard, the user will waive any rights and claims against Misaal with respect to their use and exploitation of such music, lyrics, photos and recordings provided by the users.
</li></ul>
<h4>Limitation of liability</h4>
<ul className="ul-list">
<li>We do not guarantee, represent or warrant in any manner that your use of our Platform will be uninterrupted, timely, secure or error-free. We do not warrant in any manner that the results or the outcome that may be obtained from the use of the Facilities will be accurate or reliable or effective in nature. You understand and accept that your access to the Facilities and the Misaal Talent Hunt  is solely at your own judgment and risk.
</li><li>You agree that, from time to time, we may suspend, modify, restrict or remove our Facilities for uncertain or indefinite periods of time or cancel the Facilities at any time, without notice to you.
</li><li>You expressly agree that your use of, or inability to use, the Facilities or access the Misaal Talent Hunt  are at your sole risk. The Facilities provided to you are (except as expressly stated by us) provided on an 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of the Facilities, fitness for a particular purpose, durability and non-infringement.
</li><li>You agree that your participation is of voluntary in nature and by your own risk and will have no claim against Misaal on any account whatsoever. Misaal shall not be responsible for any damage, loss or any form of physical or mental harm or injury caused to you. Further, the parents/ guardians shall ensure that the participant is physically fit and mentally fit and is not suffering from any critical health issue which could put the participant’s health at risk.
</li><li>You acknowledge that third-party links on our Platform or Services may direct you to third-party sites or pages or applications that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy of such sites or pages or applications, and we do not warrant and will not have any liability or responsibility for any third-party materials or websites or pages or applications, or for any other data, information, products or services of any third-parties.
</li><li>We may provide you with access to third-party tools over which we neither monitor nor have any control or input. You acknowledge and agree that we provide access to such tools 'as is' and 'as available' without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools. Any use by you of optional tools offered through the Platform is entirely at your own risk and discretion; and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s).
</li></ul>
<h4>Indemnification</h4>
<ul className="ul-list">
<li>The user agrees to indemnify, defend and hold harmless Misaal, its affiliates, partners, officers, directors, agents, service providers, employees, from any losses, injuries, claims, demands, proceedings, penalties, interests, costs and expenses, including attorneys’ fees, by virtue of breach of the user’s representations or obligations or covenants made under these Terms of Use or any Policies of Misaal, or claims made by any third-party due to or arising out of the breach of these Terms of Use or the Policies by the user, or the user’s violation of any law or the rights of a third-party.
</li><li>The indemnification rights of Misaal shall be in addition to any rights that may be available to Misaal under applicable law (including injunctions and specific performance reliefs), none of which shall be affected in any manner whatsoever.
</li><li>The user also agrees to indemnify, defend, release, and hold harmless Misaal, its parent, assignees, licensees, agents, partners and affiliates, sponsors, advertising agencies, used in connection with Misaal Talent Hunt , and any and all others connected with it, from any and all liability or claims arising out of or in any manner whatsoever resulting from the user’s participation in Misaal Talent Hunt  or in any aspect or phase thereof (including, without limitation, the interview and audition process), the use or reuse of the user’s appearance, name, voice, singing voice, likeness, signature, biographical information, music, photos, and/or recordings, or the breach or an alleged breach of any legal provision or the terms hereof; and further, the user discharges Misaal and undertakes to not any claim against Misaal as a result of the user’s participation or non-participation in Misaal Talent Hunt  or in any aspect or phase thereof (including, without limitation, the interview and selection process).
</li></ul>
<h4>Other provisions</h4>
<ul className="ul-list">
<li>If any term, provision, covenant or condition of this Terms of Use is held invalid or unenforceable for any reason, the remainder of the provisions will continue in full force and effect as if this Terms of Use had been executed with the invalid portion eliminated.
</li><li>The user acknowledges and represents that he or she has not relied on any representation, assertion, guarantee, warranty, collateral contract or other assurance, except those set out in this Terms of Use and other Policies.
</li><li>The rights granted to the user under this Terms of Use are not assignable or transferable, in whole or part, and is personal to the user. We reserve the right, and we can at our absolute discretion, assign the rights and obligations under this Terms of Use (or any part thereof) to an affiliate or to another entity in connection with a corporate transaction, assignment process, any other commercial arrangement or otherwise.
</li><li>Any rights of Misaal to terminate your access to the Platform and block your future access to the Platform are in addition to any other remedies which Misaal may have at law or in equity.
</li><li>In addition to the foregoing clauses, Misaal may, at any time, with or without notice, terminate these terms of the Terms of Use with you if:
</li><li>Misaal is required to do so by law (for example, where the provision of the Services to you is, or becomes, unlawful), or upon request by any law enforcement or other government agencies;
</li><li>The provision of the Facilities to you by Misaal is, in Misaal’s sole discretion, no longer commercially viable or other feasible; or
</li><li>Misaal has elected to discontinue, with or without reason, access to the Platform, the Facilities (or any part thereof).
</li><li>The user agrees that all termination shall be made at Misaal’s sole discretion and that Misaal shall not be liable to you or any third party for any termination of your account (and accompanying deletion of your account information), or your access to the Platform.
</li><li>If performance of any actions or obligation under the terms and conditions of this Terms of Use or any other provisions of the Policies, or other third parties in fulfilment of transaction (for e.g. deliveries, payment gateways etc.) are, prevented, restricted, delayed or interfered with by reason of labour disputes, strikes, acts of God, floods, lightning, severe weather, shortages of materials, rationing, utility or communication failures, earthquakes, war, revolution, acts of terrorism, civil commotion, acts of public enemies, blockade, embargo or any law, order, proclamation, regulation, ordinance, demand or requirement having legal effect of any government or any judicial authority or representative of any such government, or any other act whatsoever, whether similar or dissimilar to those referred to in this clause, which are beyond the reasonable control of Misaal or its third parties performing such services as sub-contractor or agent to Misaal and could not have been prevented by reasonable precautions (each, a "Force Majeure Event"), then Misaal shall be excused from such performance on account of the Force Majeure Event.
</li><li>These Terms of Use and the Policies shall be governed by the laws of India, and the courts in Bangalore, India, shall have exclusive jurisdiction over any issues or disputes or claims arising out of these Terms of Use or Policies or the Misaal Talent Hunt  program.
</li></ul>
<h4>Communications</h4>
<ul className="ul-list">
<li>When you use the Platform or send emails or other data, information or communication to Misaal, you agree and understand that you are communicating with Misaal through electronic records and you consent to receive communications via electronic records from Misaal periodically and as and when required.
</li><li>Misaal will communicate with you by email or by notices on the Platform or electronic records on the Platform or on your mobile number which will be deemed adequate service of notice/ electronic record to the maximum extent permitted under any applicable law.
</li></ul>

<h4>Feedback and Information</h4>
<ul className="ul-list">
<li>You agree and accept that, any creative ideas, suggestions, proposals, plans, feedback or other materials, whether online by email, postal mail or otherwise ("Feedback"), you provide to Misaal shall be deemed to be non-confidential and further Misaal reserves the right to use such information without any restriction to edit, copy, publish, distribute, translate and otherwise use in any medium any Feedback that you provide.
</li><li>Misaal may, but shall have no obligation to, respond, monitor, edit or remove any Feedback appearing on the Platform that we determine in our sole discretion to be unlawful, offensive, threatening, libelous, defamatory, obscene or otherwise objectionable or violates any party’s intellectual property or this Terms of Use.
</li><li>You hereby waive all rights under applicable law in relation to any changes or modifications to the Platform, the content therein and/ or the Facilities.
</li></ul>
<h4>Contact Information</h4>
<ul className="ul-list">
<li>Questions about the Terms of Use should be sent to us at <a href="mailto:misaal.concert@gmail.com" target="_blank" rel="noopener noreferrer">misaal.concert@gmail.com</a>
</li></ul>
      </div>
    </>
  );
};

export default Terms;
