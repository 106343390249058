import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import ContextProvider from './screens/ContextProvider';
import ScrollToView from './common/ScrollToView';

import Header from './screens/header/header';
import Footer from './screens/footer/footer';

import HomePage from './screens/HomePage/HomePage';

import './assets/css/styles.css';
import Privacy from './screens/privacy/Privacy';
import Terms from './screens/terms/Terms';
import Register from './screens/register/register';
import Participate from './screens/participate/participate';
import Qualified from './screens/qualified/qualified';

const App = () => {
  return (
    <Router>
      <ScrollToView>
        <ContextProvider>
          <Header />
          <Switch>
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            {/* <Route exact path="/register" component={Register} />
            <Route exact path="/participate" component={Participate} /> */}
            <Route exact path="/qualified" component={Qualified} />
            <Route path="*" component={HomePage} />
          </Switch>
          <Footer />
        </ContextProvider>
      </ScrollToView>
    </Router>
  );
};

export default App;
