import React from 'react';
import { Link } from 'react-router-dom';
import socialLinks from './socials';

const Footer = () => {
  const socials = socialLinks.map(({ link, imgPath, altText }, i) => {
    return (
      <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 pr-0" key={i}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img alt={altText} title={altText} src={imgPath} style={{ width: '30px', height: '30px', margin: '0 3px' }} />
        </a>
      </div>
    );
  });
  return (
    <>      
        <div className="container-fluid">
        <div className="container ptb-0">
          <div className="row footer-block">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 f-l footer-links pl-0">
              <div className="row justify-content-center align-items-center my-0 pl-0">
                  <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 mb-2 px-1" style={{whiteSpace: 'nowrap'}}>
                  @ {(new Date().getFullYear())} Misaal
                  </div>
                  <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 f-l mb-2 px-1" style={{whiteSpace: 'nowrap'}}>
                    <Link to="/privacy">Privacy</Link>
                    <Link to="/terms">Terms &amp; Conditions</Link>
                  </div>
              </div>
              
              
            </div> 
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 f-align-text text-center">
              <div className="row justify-content-center align-items-center my-0">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center mb-1" style={{whiteSpace: 'nowrap'}}>
                    <strong>WhatsApp</strong> +91 97529 63797
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 text-center mb-1" style={{whiteSpace: 'nowrap'}}>
                    <strong>Customer Support</strong> +91 97529 63767
                  </div>
              </div>             
            </div>
            
            <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 text-center">
                <div className="row align-items-center justify-content-center social-links">
                {socials}
                </div>
            </div>
          </div>
        </div>
        </div>
    </>
  );
};

export default Footer;
