import React from 'react';
import { Carousel } from 'react-bootstrap';
import showcase1 from '../../assets/img/banner_img.jpg';
import carousel1 from '../../assets/img/img_electric_guitar.png';
import instagram from '../../assets/img/icon_instagram_color.png';
import facebook from '../../assets/img/icon_fb_color.png';
import boxbg from '../../assets/img/white_bx-decorative.png';

const Showcase = () => {
  return (
    <>
      <Carousel controls={false} indicators={false}>
        <Carousel.Item>
          <img className="d-block w-100" src={showcase1} alt="" />
          <Carousel.Caption className="carousel-1">
            <img src={carousel1} alt="" className="c-1-img" />
            <h2>The <strong>Talent</strong> Hunt</h2>
            <div className="showcase-msg-box">
              <img src={boxbg} alt="" style={{position: 'absolute', left: '0', top: '0', right: '0', bottom: '0', width: '100%', height: '100%'}} />
              <div className="row py-3">
                <div className="col-7 text-right">
                <strong style={{color:'#e9208b'}}>Grand Finale</strong><br />
                  on <strong>25<sup>th</sup> July, 8 PM</strong>
                </div>
                <div className="col-5" style={{borderLeft: '1px solid #ddd'}}>
                  <small><strong>Live on</strong></small><br />
                  <a href="https://m.facebook.com/MisaalConcert/videos/1295503400792651/" target="_blank" rel="noopener noreferrer" title="Facebook"><img src={facebook} alt="Facebook" style={{width: '24%', margin: '0 7px 0 0'}} /></a>
                  <a href="https://www.instagram.com/misaal.concert/?hl=en" target="_blank" rel="noopener noreferrer" title="Instagram"><img src={instagram} alt="Instagram" style={{width: '24%'}} /></a>
                </div>
              </div>
              
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default Showcase;
