import React from 'react';
import { withRouter } from 'react-router-dom';

class ScrollToView extends React.Component {
  componentDidUpdate(prevProps) {
    const { hash: prevHash, pathname: prevPathName } = prevProps.location;
    const prevFullPath = prevHash ? `${prevPathName}${prevHash}` : prevPathName;

    const { hash, pathname } = this.props.location;
    const fullPath = hash ? `${pathname}${hash}` : pathname;

    if (hash || fullPath !== prevFullPath) {
      if (hash) {
        const el = document.getElementById(hash.substr(1));
        if (el) {
          const offsetTop = el.offsetTop - 78;
          window.scroll({ behavior: 'smooth', top: offsetTop });
        }
      } else {
        window.scrollTo(0, 0);
      }
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToView);
