import React, { useReducer } from 'react';

export const AppContext = React.createContext();

// Context Store
const initState = {
  registerModal: {
    show: false,
    applyCategory: 1
  },
  categories: []
};

const ContextProvider = props => {
  const [state, dispatch] = useReducer((state, next) => ({ ...state, ...next }), initState);

  const toggleRegisterModal = catgry => {
    dispatch({
      registerModal: {
        show: !state.registerModal.show,
        applyCategory: catgry && typeof catgry === 'number' ? catgry : 1
      }
    });
  };

  const saveCategories = categories => dispatch({ categories });

  return (
    <AppContext.Provider value={{ ...state, toggleRegisterModal, saveCategories }}>
      {props.children}
    </AppContext.Provider>
  );
};

export default ContextProvider;
