import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { AppContext } from '../../screens/ContextProvider';

import submission from '../../assets/img/icon_submit_request.png';
import screening from '../../assets/img/icon_screening.png';
import finale from '../../assets/img/icon_finale.png';
import future from '../../assets/img/icon_bright_future.png';
import img1 from '../../assets/img/img_super_singer.jpg';
import img2 from '../../assets/img/img_dancer_dynamo.jpg';
import img3 from '../../assets/img/img_promising_poet.jpg';
import img4 from '../../assets/img/img_music_maestro.jpg';
import tick from '../../assets/img/sticker_done.png';
import instagram from '../../assets/img/logo_instagram.png';
import facebook from '../../assets/img/logo_fb.png';
import usha from '../../assets/img/usha-uthup.jpg';

const imgSources = [img2, img4, img1, img3];

const Categories = () => {
  const { toggleRegisterModal, categories = [] } = useContext(AppContext);
  return (
    <>
      <div className="container-fluid">
        <br />
        <br />
        <div className="container">
          <h1 className="th-title">
            The <strong>Talent</strong>Hunt
          </h1>
          <br />
          <p className="text-center">
            Misaal is a platform for singers, dancers, poets, and music maestros to participate and exhibit their
            talent via a safe online environment. The event is organised primarily for youngsters in the age group <strong>6 to 18</strong> years. Our expert mentors will screen the entries and select few high quality talents for the Finale. The selected few participants will be awarded with a laptop, one year free premium subscription to Meritnation and other surprise gifts.
          </p>
          <h4 className="text-center">How it works?</h4>
          <p className="text-center">
          You can show case your talent irrespective of language or art form within specified categories. So, put your best foot forward!
          </p>
          <div className="row talent-area">
            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12">
              <div className="row t-h">
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                  <img src={submission} alt="SUBMISSION" title="SUBMISSION" />
                  <h4>SUBMISSION</h4>
                  <small><strong>1000+ entries<br />received</strong></small>
                  <p><img src={tick} alt="" style={{width:'80px'}} /></p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center">
                  <img src={screening} alt="SCREENING" title="SCREENING" />
                  <h4>SCREENING</h4>
                  <small><strong>15 Finalists<br />invited</strong></small>
                  <p><img src={tick} alt="" style={{width:'80px'}} /></p>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 text-center px-2">
                  <img src={finale} alt="GRAND FINALE" title="GRAND FINALE" />
                  <h4>GRAND FINALE</h4>
                  <small>Performance by 15 talented youth from across the country on <strong>25<sup>th</sup> July, 8 pm</strong>.</small>
                  <div className="row lgbg" style={{margin: '10px 0 20px'}}>
                    <div className="col-12 col-sm-12 text-center"><div style={{width: '40%', borderBottom: '1px solid #e9208b', margin: '0 auto'}}><small>Live On</small></div></div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-center px-0"><a href="https://m.facebook.com/MisaalConcert/videos/1295503400792651/" target="_blank" rel="noopener noreferrer" title="Facebook"><img src={facebook} alt="Facebook" style={{width: '80px', margin: '15px 0 10px'}} /></a></div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 text-center"><a href="https://www.instagram.com/misaal.concert/?hl=en" target="_blank" rel="noopener noreferrer" title="Instagram"><img src={instagram} alt="Instagram" style={{width: '90px', margin: '10px 0'}} /></a></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
              <div className="row t-h">
                <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-12 text-center">
                  <img src={future} alt="BUILD A BRIGHT FUTURE" />
                  <h4>BUILD A BRIGHT FUTURE</h4>
                </div>
              </div>
            </div>
          </div>
        </div></div>
        <div className="container-fluid lgbg">
        <div className="container">
          <br />
          <h1 className="th-title">
          Meet <strong>our Mentor - Usha Uthup</strong>
          </h1>
          <br />
          <div className="row px-4 py-3">
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12 col-12"></div>
            <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-center">
              <img src={usha} alt="Usha Uthup" title="Usha Uthup" style={{width: '95%', margin: '7px auto'}} />
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-justify">
            <p>Padma Shri Usha Uthup has served as a role model for generations of young Indians and has been an unwavering ambassador for traditional Indian values. With her trademark Kanjeevaram and her beaming smile, she has spread a message of love and unity, peace and harmony, tolerance and integrity, and happiness - through music, across the world. Ushaji's melody speaks a universal language and transcends religion, race, nationality and caste. She has given people in far flung cultures an unexpected image of an Indian woman: strong, independent, humorous, intelligent and loaded with talent.</p>
            <p><strong>Misaal</strong> is proud to have <strong>Usha Uthup</strong> as our Judge and Mentor.</p>
            </div>
          </div>
          </div></div>
          <br />
          <div className="container-fluid">
        <div className="container">
          <div className="row">
            <h4 className="text-center w-100">How should I participate?</h4>
          </div>
          <br />
          <div className="row ml-0">
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="row">
                <h6>What is the eligibility criteria?</h6>
              </div>
              <div className="row">
                <p>
                  Young Talent with passion to serve society, age group <strong>6 to 18 years</strong> can participate. It is open to
                  all Indians across the Globe.
                </p>
              </div>
              <div className="row">
                <h6>How to enter the Talent Hunt?</h6>
              </div>
              <div className="row">
                <p>
                  You can participate Virtually, Remotely and Safely. Record a video of your performance and upload it
                  on this site. The video must be fairly recent (<strong>not more than 30 days old</strong>).
                </p>
              </div>
              <div className="row">
                <h6>How can I get in touch?</h6>
              </div>
              <div className="row">
                <p>
                  <a href="https://www.instagram.com/misaal.concert/?hl=en" target="_blank" rel="noopener noreferrer">
                    Instagram
                  </a>
                  ,{' '}
                  <a href="https://m.facebook.com/MisaalConcert/videos/1295503400792651/" target="_blank" rel="noopener noreferrer">
                    Facebook
                  </a>
                  ,{' '}
                  <a href="https://wa.me/919752963797" target="_blank" rel="noopener noreferrer">
                    WhatsApp
                  </a>{' '}
                  and our website. You can also reach us on - <strong>WhatsApp</strong> No +91 97529 63797 or <strong>Customer Support</strong> No +91
                  97529 63767.
                </p>
              </div>
              <div className="row">
                <h6>When will the results come?</h6>
              </div>
              <div className="row">
                <p>
                  Grand Finale will be on <strong>25<sup>th</sup> July</strong>.
                </p>
              </div>
              <div className="row">
                <h6>Who will shortlist participants?</h6>
              </div>
              <div className="row">
                <p>
                  Our panelists - eminent personalities from fields of music, dance, education, etc. will screen all the
                  entries.
                </p>
              </div>
              <div className="row">
                <h6>Who are the viewers?</h6>
              </div>
              <div className="row">
                <p>Gen X, Y, Z, Parents, Families and well-wishers across the globe.</p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="categories-padding">
                <div className="row">
                  <h6>How will we support the society?</h6>
                </div>
                <div className="row">
                  <p>Entire proceeds will go to <strong>Magic Bus</strong>, our NGO partner.</p>
                </div>
                <div className="row">
                  <h6>What will be the recognition for my participation?</h6>
                </div>
                <div className="row">
                  <p>The selected few participants will be awarded with a <strong>laptop, one year free premium subscription to Meritnation and other surprise gifts</strong>.</p>
                </div>
                <div className="row">
                  <h6>How to submit my entry?</h6>
                </div>
                <div className="row">
                  <ul>
                    <li>
                      You can submit a <strong>video recording</strong> &mdash; photos, audio recordings, and other formats are not permitted.
                    </li>
                    <li>
                      The format of the file can be .mp4, .avi, .wmv &amp; .mov and file size should not exceed <strong>30 MB</strong> for online
                      submissions, and <strong>16 MB</strong> for WhatsApp.
                    </li>
                    {/* <li>
                    You can submit <strong>only one entry across categories</strong>.
                    </li> */}
                    <li>
                      Any kind of editing, tampering, video effects, and embellishments is prohibited in the submitted video. Any addition of text, title, or animation is also forbidden.
                    </li>
                    <li>Entries with inappropriate language or content will be rejected.</li>
                    <li>Video must clearly showcase the performance.</li>
                    <li>There is no requirement of any specific recording device.</li>
                  </ul>
                </div>
                <div className="row p-1" style={{fontStyle: 'italic', fontSize: '0.95rem'}}>
                  This is an event organized by the youth for a social cause with minimal cost. So we apologise and request you to excuse us for any mistake or issue.
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
      <div className="container-fluid lgbg">
        <div className="container">
          <br />
          <h1 className="th-title">
            Our <strong>Categories</strong>
          </h1>
          <br />
          <div className="row ml-0 categories-list py-2">
            {imgSources.map((imgSrc, index) => {
              const areCategories = categories && categories.length > 0;
              const catgryText = areCategories ? categories[index].name : `category-${index}`;
              const categoryId = areCategories ? categories[index].categoryId : 1;
              return (
                <div key={catgryText} className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 text-center pl-0">
                  <img alt={catgryText} title={catgryText} src={imgSrc} style={{ width: '252px', height: '206px' }} />
                  <p>{areCategories && catgryText}</p>
                  <Button variant="link" onClick={() => toggleRegisterModal(categoryId)} disabled>
                    PARTICIPATE
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Categories;
