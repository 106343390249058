export const getAgeByDob = date => {
  var today = new Date();
  var birthDate = new Date(date);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const getDatePrefix = () => {
  const today = new Date();
  let date = today.getDate().toString();
  date = date.length > 1 ? date : `0${date}`;
  let month = today.getMonth().toString();
  month = month.length > 1 ? month : `0${month}`;
  return `${month}/${date}`;
};

export const getRevisedDate = (subtractor = 0) => {
  const dateMonthString = getDatePrefix();
  const presentYear = (new Date().getFullYear() - subtractor).toString();
  return `${dateMonthString}/${presentYear}`;
};
