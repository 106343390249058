import React from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import finale from '../../assets/img/Img_Finale.png';
import logo from '../../assets/img/Misaal_logo.png';

const NavMenuOptions = [
  {
    id: 'home',
    label: 'Home',
    href: ''
  },
  {
    id: 'about',
    label: 'About Misaal',
    href: '#about'
  },
  // {
  //   id: 'categories',
  //   label: 'Categories',
  //   href: '#categories'
  // },
  {
    id: 'talentHunt',
    label: 'The Talent Hunt',
    href: '#talentHunt'
  },
  // {
  //   id: 'howItWorks',
  //   label: 'How it works',
  //   href: '#howItWorks'
  // },
  // {
  //   id: 'guideLines',
  //   label: 'Guidelines',
  //   href: '#guideLines'
  // },

  {
    id: 'partners',
    label: 'Our Partners',
    href: '#partners'
  },
  {
    id: 'mediaArticles',
    label: 'Media Articles',
    href: '#mediaArticles'
  },
  {
    id: 'donate',
    label: 'Donate Now',
    href: '#donate'
  },
  // {
  //   id: 'faq',
  //   label: "FAQ's",
  //   href: '#faq'
  // }
];

const Header = () => {
  const location = useLocation();

  const closeMenuOnNavigation = () => {
    document.getElementById('headerNavbar').classList.remove('show');
  };

  return (
    <div id="navbar" className="sticky-top bg-light">

      <header className=" bg-light">
        <nav className="navbar navbar-expand-lg navbar-light">
          <Link to="/home" className="navbar-brand">
            <img
              src={logo}
              alt="Misaal - Young India Inspires"
              title="Misaal - Young India Inspires"
              className="logo"
            />
          </Link>
          <div className="d-md-block d-lg-none ml-auto">
            <NavLink
              exact
              className="border-0 bgPrimary text-white rounded-pill font-weight-bold py-1 px-3 my-auto"
              fragment="donate"
              to={`/home#donate`}
              onClick={closeMenuOnNavigation}
            >
              <span>Donate Now</span>
            </NavLink>
          </div>
          <button
            className="navbar-toggler ml-xl-auto border-0"
            data-toggle="collapse"
            data-target="#headerNavbar"
            type="button"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse text-center text-xl-left collapse" id="headerNavbar" aria-expanded="false">
            <ul className="navbar-nav mx-auto menuList">
              {NavMenuOptions.map(({ id, label, href }) => (
                <li className="navbar-link pointer" key={href}>
                  <NavLink
                    exact
                    activeClassName={location.hash === href ? 'active' : ''}
                    className={id !== 'donate' ? "font-weight-bold text-center" : "d-lg-block d-md-none d-none ml-auto border-0 bgPrimary text-white rounded-pill font-weight-bold py-1 px-3 my-auto ml-5"}
                    fragment={id}
                    to={`/home${href}`}
                    onClick={closeMenuOnNavigation}
                  >
                    <span>{label}</span>
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>
          <div className="d-lg-block d-md-none d-none ml-auto" style={{ position: 'relative', paddingLeft: '75px' }}>
            <img src={finale} alt="Finale" style={{ width: '65px', position: 'absolute', left: '0px', top: '-13px' }} />
            <h5>Grand Finale</h5>
            <p>on <strong>25<sup>th</sup> July</strong></p>
          </div>
        </nav>
      </header>
    </div>

  );
};

export default Header;
