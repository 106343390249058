import React from 'react';
import logo from '../../assets/img/Misaal_logo.png';
import img1 from '../../assets/img/magic_bus_logo.png';
import img2 from '../../assets/img/tripod_logo.png';
import img3 from '../../assets/img/meritnation_logo.png';
import ananya from '../../assets/img/img_Ms_Ananya.jpg';
import ahana from '../../assets/img/img_Ms_Ahana.jpg';
import media_article_hed from '../../assets/img/higher_education_digest.jpg';
import media_article_crs from '../../assets/img/crs_mandate.jpg';
import media_article_crs_facebook from '../../assets/img/crs_mandate_facebook.jpg';

const Partners = () => {
  return (
    <>
      <div className="container-fluid lgbg">
        <br />
        <div className="container">
          <h1>Our <strong>Partners</strong></h1><br />
          <div className="judges">
            <div className="row ml-0 partners">
              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12 text-center h-100 pl-0 my-2 whiteBg">
                <div className="row categories-inner">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center pl-0" style={{ margin: '0' }}><h6>Support Partners</h6></div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center pl-0">
                    <a href="https://www.magicbus.org/about-us" className="text-center" target="_blank" rel="noopener noreferrer"><img alt="Support Partners" title="Support Partners" src={img1} /></a>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 text-center h-100 pl-0">
                    <a href="http://tripodentertainment.in/" target="_blank" rel="noopener noreferrer"><img alt="Support Partners" title="Support Partners" src={img2} /></a>
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 text-center my-2 h-100 pl-0 whiteBg">
                <div className="row categories-inner">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center pl-0" style={{ margin: '0' }}><h6>Online Learning Partner</h6></div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center pl-0" style={{ height: '123px' }}>
                    <a href="https://www.meritnation.com/" target="_blank" rel="noopener noreferrer"><img alt="Support Partners" title="Online Learning Partner" src={img3} style={{ width: '100%', margin: '1.4rem 0' }} /></a>
                  </div>
                </div>
              </div>
            </div>
          </div><br />
        </div>
      </div>
      <div className="container-fluid ptb-5">
        <br />
        <div className="container">
          <h1>Voice of a <strong>Gen Z</strong></h1>
          <br />
          <p className="text-center"><img alt="Ananya" title="Ananya" src={ananya} style={{ width: '300px' }} /></p>
          <p className="p-2" style={{ textAlign: 'justify' }}>"Every morning I  wake up to see a world which is changing drastically. COVID has challenged our existence in more ways than one. Health, education and financial security have been badly hit. In such trying times, the youth of India have to come together and do their bit. We have the power and potential to fight against poverty in our own way. And my dream is to make  it possible. I believe we can do it only if we choose to work together. Let's create a Misaal."</p>
          <h6 className="text-center" style={{ color: '#e9208b' }}>- Ananya Dalmia, The Curator</h6>
          <p className="text-center p-2">Student Class XI, Hill Spring International School</p>
          <br />

          <p className="text-center"><img alt="Ahana " title="Ahana" src={ahana} style={{ height: '300px', borderRadius: '40px' }} /></p>
          <p className="p-2" style={{ textAlign: 'justify' }}>"We never know how strong we are until being strong is the only choice we have! We have all been adversely hit by COVID. There is no better time than today to stay united and remain Strong. Let’s all come together and do our bit to help society fight the menace of this deadly virus. Every contribution matters – let’s put our best foot forward and create Misaal."</p>
          <h6 className="text-center" style={{ color: '#e9208b' }}>- Ahana  Dalmia, The Co-Curator</h6>
          <p className="text-center p-2">Student Class VII, Vibgyor High School</p>
        </div>
      </div>
      <br />
    </>
  );
};

export default Partners;
