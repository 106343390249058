import React, { useContext, useState, useReducer, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, ProgressBar, Alert } from 'react-bootstrap';
import axios from 'axios';

import Dropzone from 'react-dropzone';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { AppContext } from '../../screens/ContextProvider';
import statesArray from '../../helpers/statesArray';
import { getAgeByDob, getRevisedDate } from '../../helpers/dateHelpers';

const initState = {
  name: '',
  fatherName: '',
  dateOfBirth: '',
  age: 0,
  address: '',
  city: '',
  state: '',
  pinCode: '',
  emailId: '',
  categoryId: 1,
  phoneNumber: '',
  alternatePhoneNumber: '',
  whatsAppNumber: '',
  whatsApp1: false,
  whatsApp2: false,
  useLinks: false,
  videoLink1: '',
  videoLink2: '',
  videoLink3: '',
  agreeTerms: false
};

const initAlertState = {
  showAlert: false,
  alertType: 'success',
  alertText: ''
};

const inituploadError = {
  showUploadAlert: false,
  uploadAlertText: ''
};

// min 6 years
const maxDate = new Date(getRevisedDate(6));

// max 18 years
const minDate = new Date(getRevisedDate(18));

const Qualified = props => {
  const {
    registerModal: { applyCategory },
    categories = [],
    saveCategories
  } = useContext(AppContext);

  const [formState, setFormState] = useReducer((state, next) => ({ ...state, ...next }), initState);

  const {
    name,
    fatherName,
    dateOfBirth,
    age,
    address,
    city,
    state,
    pinCode,
    emailId,
    categoryId,
    phoneNumber,
    alternatePhoneNumber,
    whatsApp1,
    whatsApp2,
    useLinks,
    videoLink1,
    videoLink2,
    videoLink3,
    agreeTerms
  } = formState;

  const [videoFiles, setVideoFiles] = useState([null,null, null]);
  const [fileInfos, setFileInfos] = useState(
    [
    {fileName: '', fileSize: 0, uploaded: false},
    {fileName: '', fileSize: 0, uploaded: false},
    {fileName: '', fileSize: 0, uploaded: false}
  ]);

  const [progress, setProgress] = useState([0, 0, 0]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const [alertState, setAlertState] = useReducer((state, next) => ({ ...state, ...next }), initAlertState);
  const { showAlert, alertType, alertText } = alertState;

  const [uploadErrorState, setUploadErrorState] = useReducer((state, next) => ({ ...state, ...next }), inituploadError);
  const { showUploadAlert, uploadAlertText } = uploadErrorState;

  const [successModal, toggleSuccessModal] = useState(false);

  // fetch categories if not present in context
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}api/participants/categories`);
        // console.log(response.data);
        saveCategories(response.data);
      } catch (err) {
        console.log(err);
      }
    };
    if (categories && categories.length === 0) fetchCategories();
  }, []);

  useEffect(() => {
    setFormState({ categoryId: applyCategory });
  }, [applyCategory]);

  // Reset Modal State
  const clearRegisterFormState = () => {
    setFormState({ ...initState, categoryId: applyCategory });
    setVideoFiles([null, null, null]);
    setFileInfos(
      [
        {fileName: '', fileSize: 0, uploaded: false},
        {fileName: '', fileSize: 0, uploaded: false},
        {fileName: '', fileSize: 0, uploaded: false}
      ]
    );
    setIsFormValid(false);
    setUploadErrorState(inituploadError);
    setProgress([0, 0, 0]);
    setAlertState(initAlertState);
  };

  useEffect(() => {
    clearRegisterFormState();
  }, []);

  const validateForm = () => {
    setIsFormValid(true);
    if (
      !name ||
      !fatherName ||
      !dateOfBirth ||
      !emailId ||
      !address ||
      !city ||
      !state ||
      !pinCode ||
      !phoneNumber ||
      !categoryId ||
      !agreeTerms ||
      (useLinks && (!videoLink1)) ||
      (!useLinks && (!videoFiles[0]))
    ) {
      setIsFormValid(false);  
    }
    if (videoLink1) {
      if (videoLink1.indexOf('https://drive.google.com/') != 0) {
        setIsFormValid(false);
      }
    }
    if (videoLink2) {
      if (videoLink2.indexOf('https://drive.google.com/') != 0) {
        setIsFormValid(false);
      }
    }
    if (videoLink3) {
      if (videoLink3.indexOf('https://drive.google.com/') != 0) {
        setIsFormValid(false);
      }
    }
  };

  useEffect(() => {
    validateForm();
  }, [formState, videoFiles]);

  /* Change Handlers */
  const handleDrop = (index, files) => {
    if (files.length > 0) {
      let newVideoFiles = [...videoFiles];
      newVideoFiles[index] = files[0];
      setVideoFiles(newVideoFiles);
      let newFileInfos = [...fileInfos];
      newFileInfos[index] = {
            fileName: files[0].name,
            fileSize: parseInt(files[0].size / 1024 / 1024),
            uploaded: false
            };
      setFileInfos(newFileInfos);
      setAlertState(initAlertState);
    } else {
      setAlertState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Only .mp4, .avi, .wmv and .mov files accepted. File size should not exceed 30MB.'
      });
      let newVideoFiles = [...videoFiles];
      newVideoFiles[index] = null;
      setVideoFiles(newVideoFiles);
      let newFileInfos = [...fileInfos];
      newFileInfos[index] = {
            fileName: '',
            fileSize: 0,
            uploaded: false
            };
      setFileInfos(newFileInfos);
    }
  };

  const handleDateChange = dateOfBirth => {
    if (dateOfBirth) {
      const age = getAgeByDob(dateOfBirth);
      if (age && age > 5 && age < 19) {
        setFormState({ dateOfBirth: new Date(dateOfBirth), age });
      }
    }
  };

  const handleDateBlur = ({ target: { value: dob } }) => {
    if (dob) {
      const dateOfBirth = new Date(dob);
      const age = getAgeByDob(dateOfBirth);
      if (age && age > 5 && age < 19) {
        setFormState({ dateOfBirth, age });
      } else {
        setFormState({ dateOfBirth: '', age: 0 });
      }
    } else {
      setFormState({ dateOfBirth: '', age: 0 });
    }
  };

  const handleNameChange = ({ target: { name, value } }) => setFormState({ [name]: value });

  const handleNameBlur = ({ target: { name, value } }) => setFormState({ [name]: `${value}`.trim() });

  const handleNumberChange = ({ target: { name, value } }) => {
    if (value === '' || !isNaN(value)) {
      setFormState({ [name]: +value || '' });
    }
  };

  const handleNumberBlur = ({ target: { name, value } }) => {
    if (value.trim() === '' || !isNaN(value.trim())) {
      const trimmedVal = +value.trim() || '';
      setFormState({ [name]: trimmedVal });
    }
  };

  const handlePhoneChange = ({ target: { name, value } }) => {
    if (value === '' || !isNaN(value)) {
      setFormState({ [name]: value });
    }
  };

  const handlePhoneBlur = ({ target: { name, value } }) => {
    if (value.trim() === '' || !isNaN(value.trim())) {
      const trimmedVal = value.trim() || '';
      setFormState({
        [name]: trimmedVal
      });
    }
  };

  const handleEmailChange = ({ target: { name, value } }) => setFormState({ [name]: value });

  const handleEmailBlur = ({ target: { name, value } }) => {
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegEx.test(value)) {
      setFormState({ [name]: value });
    } else {
      setFormState({ [name]: '' });
    }
  };

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    if (name.includes('1')) {
      setFormState({
        whatsApp1: checked,
        whatsApp2: whatsApp2 && !checked,
        whatsAppNumber: checked ? phoneNumber : whatsApp2 && !checked ? alternatePhoneNumber : ''
      });
    } else {
      setFormState({
        whatsApp1: whatsApp1 && !checked,
        whatsApp2: checked,
        whatsAppNumber: checked ? alternatePhoneNumber : whatsApp1 && !checked ? alternatePhoneNumber : ''
      });
    }
  };

  const handleRadioChange = ({ target: { name, value } }) => setFormState({ [name]: +value });

  const handleRegisterUploadSuccess = () => toggleSuccessModal(true);

  const handleUseLinkChange = ({ target: { name, checked } }) => setFormState({ [name]: checked });
  const handleAgreeTermsChange = ({ target: { name, checked } }) => setFormState({ [name]: checked });

  const uploadVideoToDb = async (url, videoFile, index) => {
    try {
      setSubmissionInProgress(true);
      if (url) {
        const contentType = videoFile.type;
        const res = await axios.put(url, videoFile, {
          headers: {
            'Content-Type': contentType
          },
          onUploadProgress: progressEvent => {
            const { loaded, total } = progressEvent;
            setSubmissionInProgress(true);
            let newProgress = [...progress];
            newProgress[index] = parseInt(Math.round((loaded * 100) / total));
            setProgress(newProgress);
          }
        });
      }
      return true;
    } catch (error) {
      setSubmissionInProgress(false);
      // console.log(error.response);
      if (error.response) {
        const { data } = error.response;
        if (typeof data === 'string') {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: 'Server Error, Please Try Again!'
          });
        }
      } else {
        setUploadErrorState({
          showUploadAlert: true,
          uploadAlertText: 'Network Error, Please Try Again!'
        });
      }
      return false;
    }
  };

  const registerUser = async data => {
    try {
      setUploadErrorState({
        showUploadAlert: false,
        uploadAlertText: ''
      });
      setSubmissionInProgress(true);
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/participants/qualified-upload`, data);
      // console.log({ res });
      const { content, registration } = res.data;
      if (registration.status) {
        // registered success
        if (content && content.status) {
          if (useLinks) {
            handleRegisterUploadSuccess();
            clearRegisterFormState();
          } else {
            if (content.presignedUrls) {
              let indexes = [];
              for (let index=0; index<videoFiles.length; index++) {
                indexes.push(index);
              }
              for (let tryCount=0; tryCount<3; tryCount++) {
                let newIndexes = [];
                for (let index=0; index<indexes.length; index++) {
                  let itemIndex = indexes[index];
                  let result = await uploadVideoToDb(content.presignedUrls[itemIndex], videoFiles[itemIndex], itemIndex); 
                  if (result) {
                    let newFileInfos = [...fileInfos];
                    newFileInfos[index].uploaded = true;
                    setFileInfos(newFileInfos);
                  } else {
                    newIndexes.push(itemIndex);
                  }
                }
                indexes = newIndexes;
              }
              setSubmissionInProgress(false);
              if (indexes.length == 0) {
                handleRegisterUploadSuccess();
                clearRegisterFormState();
              } else {
                setUploadErrorState({
                  showUploadAlert: true,
                  uploadAlertText: 'Unable to upload files, please try again.'
                });
              }
              return;
            }
          }
        } else {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: content.message
          });
        }
      } else {
        setUploadErrorState({
          showUploadAlert: true,
          uploadAlertText: registration.message
        });
      }
      setSubmissionInProgress(false);
    } catch (error) {
      setSubmissionInProgress(false);
      // console.log(error.response);
      if (error.response) {
        const { data } = error.response;
        if (typeof data === 'string') {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: 'Server Error, Please Try Again'
          });
        } else if (!data.registration.status) {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: data.registration.message
          });
        }
      } else {
        setUploadErrorState({
          showUploadAlert: true,
          uploadAlertText: 'Network Error, Please Try Again!'
        });
      }
    }
  };

  const handleSubmitEntry = async () => {
    const omitKeys = ['age', 'categoryId', 'whatsApp1', 'whatsApp2', 'useLinks', 'videoLink1', 'videoLink2', 'videoLink3'];
    const requestBody = {
      content: {
        categoryId,
        useLinks
      },
      registration: {
        loginId: phoneNumber
      }
    };
    Object.keys(formState).forEach(key => {
      if (!omitKeys.includes(key)) {
        requestBody.registration[key] = formState[key];
      }
    });
    const [, month, date, year] = new Date(dateOfBirth).toDateString().split(' ');
    const formattedDOB = `${date}-${month}-${year}`;
    requestBody.registration['dateOfBirth'] = formattedDOB;
    requestBody.content['whatsappUpload'] = false;
    if (useLinks) {
      requestBody.content['urls'] = [videoLink1, videoLink2, videoLink3];
    } else {
      let fileNames = [];
      videoFiles.forEach(videoFile => {
        if (videoFile) {
          fileNames.push(videoFile.name);
        } else {
          fileNames.push(null);
        }
      });
      requestBody.content['fileNames'] = fileNames;
    }
    registerUser(requestBody);
  };

  return (
    <>
      <div className="container-fluid">
        <br />
        <br />
        <div className="container">
          <h1 className="th-title">
            <strong>Qualified Candidate Entries</strong>
          </h1>
          <br />

          <div id="register-content" className="d-flex align-content-center flex-column flex-lg-row-reverse">
            <section id="register-form-container" className="flex-grow-1 mx-lg-4">
              <div className="container">
                <p className="form-title font-large">Participant Information</p>
                <Form id="register-form">
                  <Form.Control
                    type="text"
                    value={name}
                    name="name"
                    placeholder="PARTICIPANT'S NAME*"
                    className="bg-form-control border-0"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                  />
                  <Form.Control
                    type="text"
                    value={fatherName}
                    name="fatherName"
                    placeholder="FATHER'S/GUARDIAN'S NAME*"
                    className="bg-form-control border-0"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                  />
                  <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                    <ReactDatePicker
                      dateFormat={['MM/dd/yyyy', 'MM-dd-yyyy']}
                      placeholderText="BIRTH DATE*  (mm/dd/yyyy)"
                      selected={dateOfBirth}
                      onChange={handleDateChange}
                      onBlur={handleDateBlur}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={minDate}
                      maxDate={maxDate}
                      withPortal
                      className="w-100 bg-form-control mb-0 border-0 p-1 font-x-medium date-field"
                      wrapperClassName="w-60"
                    />
                    <Form.Text className="w-30 font-medium">Age: {age} Years</Form.Text>
                  </Form.Group>
                  {(age < 6 || age > 18) && (
                    <Form.Text className="text-secondary ml-1 font-medium">
                      Select Valid Date (mm/dd/yyyy) & Age must be between 6-18 years
                    </Form.Text>
                  )}
                  <Form.Control
                    type="text"
                    value={address}
                    name="address"
                    placeholder="ADDRESS*"
                    className="bg-form-control border-0"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                  />
                  <Form.Control
                    type="text"
                    value={city}
                    name="city"
                    placeholder="CITY*"
                    className="bg-form-control border-0"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                  />
                  <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                    <Form.Control
                      as="select"
                      value={state}
                      name="state"
                      onChange={handleNameChange}
                      className="bg-form-control border-0 w-60"
                    >
                      <option value="">STATE*</option>
                      {statesArray.map(({ name, code }) => (
                        <option key={code} value={code}>
                          {name}
                        </option>
                      ))}
                    </Form.Control>
                    <Form.Control
                      type="text"
                      value={pinCode}
                      name="pinCode"
                      maxLength="6"
                      placeholder="PINCODE*"
                      className="bg-form-control border-0 w-35"
                      onChange={handleNumberChange}
                      onBlur={handleNumberBlur}
                    />
                  </Form.Group>
                  <Form.Control
                    type="email"
                    name="emailId"
                    value={emailId}
                    placeholder="EMAIL ID*"
                    className="bg-form-control border-0"
                    onChange={handleEmailChange}
                    onBlur={handleEmailBlur}
                  />
                  <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="phone-code1" className="border-0 font-x-medium">
                        +91
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="tel"
                      value={phoneNumber}
                      name="phoneNumber"
                      maxLength="10"
                      placeholder="PHONE 1*"
                      className="bg-form-control border-0 w-50"
                      onChange={handlePhoneChange}
                      onBlur={handlePhoneBlur}
                    />
                    <Form.Check
                      inline
                      label="WhatsApp"
                      name="whatsApp1"
                      checked={whatsApp1}
                      type="checkbox"
                      id="whatsApp-1"
                      className="w-30 ml-lg-2 font-x-medium"
                      onChange={handleCheckboxChange}
                    />
                  </Form.Group>
                  <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                    <InputGroup.Prepend>
                      <InputGroup.Text id="phone-code2" className="border-0 font-x-medium">
                        +91
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="tel"
                      value={alternatePhoneNumber}
                      name="alternatePhoneNumber"
                      maxLength="10"
                      placeholder="PHONE 2"
                      className="bg-form-control border-0 w-50"
                      onChange={handlePhoneChange}
                      onBlur={handlePhoneBlur}
                    />
                    <Form.Check
                      inline
                      label="WhatsApp"
                      name="whatsApp2"
                      checked={whatsApp2}
                      type="checkbox"
                      id="whatsApp-2"
                      className="w-30 ml-lg-2 font-x-medium"
                      onChange={handleCheckboxChange}
                    />
                  </Form.Group>
                </Form>
              </div>
            </section>
            <section className="divider"></section>
            <section id="file-upload" className="flex-grow-1 mt-5 mt-lg-0 mx-lg-4">
              <div className="container">
                <section id="form-category" className="mb-5">
                  <p className="form-title font-large">Select Category*</p>
                  {categories.map(({ name, categoryId: catId }) => (
                    <Form.Check
                      type="radio"
                      key={catId}
                      label={name}
                      value={catId}
                      checked={categoryId === catId}
                      name="categoryId"
                      onChange={handleRadioChange}
                      id={`category${catId}`}
                      className="col-sm-12"
                    />
                  ))}
                </section>
                <section id="video-upload" className="mt-5">
                  <p className="form-title font-large">Your Videos*</p>
                  <p><Form.Check
                      inline
                      label="I have my videos already on Google Drive"
                      name="useLinks"
                      checked={useLinks}
                      type="checkbox"
                      id="useLinks"
                      className="ml-lg-2 font-x-medium"
                      onChange={handleUseLinkChange}
                    /> 
                    </p>
                  {showAlert && (
                    <Alert variant={alertType} className="pr-0">
                      <small className="p-0 m-0">{alertText}</small>
                    </Alert>
                  )}
                  {!formState.useLinks && ( 
                  <Dropzone
                    onDrop={ acceptedFiles => { handleDrop(0, acceptedFiles) } }
                    accept="video/mp4,video/webm,video/quicktime,video/avi,.flv,.mwv"
                    maxSize={50*1024*1024}
                    disabled={submissionInProgress}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {videoFiles[0] && fileInfos[0].fileName && fileInfos[0].fileSize ? (
                          <div className="fileUploadInfo">
                            <span>{fileInfos[0].fileName}</span>
                            <span className="ml-1">{fileInfos[0].fileSize}Mb</span>
                            <ProgressBar
                              variant="success"
                              now={fileInfos[0].uploaded ? 100 : progress[0]}
                              label={`${fileInfos[0].uploaded ? 100 : progress[0]}%`}
                              className="mt-4 mb-3 progressBar"
                            />
                          </div>
                        ) : (
                          <>
                            <p>Drop Your Introduction Video Here*</p>
                            {/* <p className="my-2">Or</p> */}
                            <Button className="border-0 bgPrimary text-white rounded-pill">SELECT FILE</Button>
                          </>
                        )}
                      </div>
                    )}
                  </Dropzone>)} 
                  {formState.useLinks && ( 
                  <Form.Control
                    type="text"
                    value={videoLink1}
                    name="videoLink1"
                    placeholder="Introduction Video Link*"
                    className="bg-form-control border-0"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    /> )}
                  <br/>
                  {!formState.useLinks && ( 
                  <Dropzone
                    onDrop={ acceptedFiles => { handleDrop(1, acceptedFiles) } }
                    accept="video/mp4,video/webm,video/quicktime,video/avi,.flv,.mwv"
                    maxSize={50*1024*1024}
                    disabled={submissionInProgress}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {videoFiles[1] && fileInfos[1].fileName && fileInfos[1].fileSize ? (
                          <div className="fileUploadInfo">
                            <span>{fileInfos[1].fileName}</span>
                            <span className="ml-1">{fileInfos[1].fileSize}Mb</span>
                            <ProgressBar
                              variant="success"
                              now={fileInfos[1].uploaded ? 100 : progress[1]}
                              label={`${fileInfos[1].uploaded ? 100 : progress[1]}%`}
                              className="mt-4 mb-3 progressBar"
                            />
                          </div>
                        ) : (
                          <>
                            <p>Drop Your Performance Video Here</p>
                            {/* <p className="my-2">Or</p> */}
                            <Button className="border-0 bgPrimary text-white rounded-pill">SELECT FILE</Button>
                          </>
                        )}
                      </div>
                    )}
                  </Dropzone> )} 
                  {formState.useLinks && ( 
                  <Form.Control
                    type="text"
                    value={videoLink2}
                    name="videoLink2"
                    placeholder="Performance Video Link"
                    className="bg-form-control border-0"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    /> )}                
                  <br/>
                  {!formState.useLinks && ( 
                  <Dropzone
                    onDrop={ acceptedFiles => { handleDrop(2, acceptedFiles) } }
                    accept="video/mp4,video/webm,video/quicktime,video/avi,.flv,.mwv"
                    maxSize={50*1024*1024}
                    disabled={submissionInProgress}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        {videoFiles[2] && fileInfos[2].fileName && fileInfos[2].fileSize ? (
                          <div className="fileUploadInfo">
                            <span>{fileInfos[2].fileName}</span>
                            <span className="ml-1">{fileInfos[2].fileSize}Mb</span>
                            <ProgressBar
                              variant="success"
                              now={fileInfos[2].uploaded ? 100 : progress[2]}
                              label={`${fileInfos[2].uploaded ? 100 : progress[2]}%`}
                              className="mt-4 mb-3 progressBar"
                            />
                          </div>
                        ) : (
                          <>
                            <p>Drop Your Performance Video Here</p>
                            {/* <p className="my-2">Or</p> */}
                            <Button className="border-0 bgPrimary text-white rounded-pill">SELECT FILE</Button>
                          </>
                        )}
                      </div>
                    )}
                  </Dropzone>)} 
                  {formState.useLinks && ( 
                  <Form.Control
                    type="text"
                    value={videoLink3}
                    name="videoLink3"
                    placeholder="Performance Video Link"
                    className="bg-form-control border-0"
                    onChange={handleNameChange}
                    onBlur={handleNameBlur}
                    /> )}
                  <div className="text-center">
                    <small>Only .mp4, .avi, .wmv and .mov files accepted. File size should not exceed 50MB.</small>
                  </div>
                  
                </section>
              </div>
            </section>
          </div>

          {showUploadAlert && (
            <Alert
              variant="danger"
              onClose={() => {
                setUploadErrorState(inituploadError);
              }}
              dismissible
              className="text-center mb-0 bg-danger text-light"
              id="uploadError"
            >
              <small>{uploadAlertText}</small>
            </Alert>
          )}
          <br/>
          <div id="termsAndConditions"><small>
            <b>Terms of Participation</b>
            <p style={{ textAlign: 'justify' }}>
              <p>By submitting my artistic work like dance/music/singing/poetry, me and my parents/guardian are confirming 
                to the below mentioned terms and conditions:</p>
              <ul>
                <li>That I am between the age of 6-18 years old and I have the requisite permission of my parents/guardian to participate in this event.</li>
                <li>That the work that I am submitting contains my own voice/poetry/musical/artistic creation which is not plagiarised / copied / reproduced in part or in full from the creation of any other person.</li>
                <li>I hereby give you non-exclusive and non-terminable permission on non – commercial basis to reproduce, stream, promote your artistic work, in all editions and formats (including, without limitation), all print and electronic medium editions by Misaal Concert only for promoting the initiative/social cause for which this event is put in place. The rights granted for the Content shall include streaming of the video, edit and promotion by the Magic Bus, MeritNation, Misaal Concert throughout the world.</li>
                <li>I will not be entitled to claim or receive any royalty / remuneration / or money for any reason whatsoever as consideration with respect to the artistic work submitted by myself.</li>
                <li>In case it is found that I have violated any of the terms and conditions mentioned above, I shall be liable to return any gift / prize / recognition / award that I may get from the organisers and also compensate for any damages suffered by them.</li>
              </ul>
            </p>
            </small>
          </div>
          <div className="d-flex justify-content-center align-content-center py-4">
            <Form.Check
              inline
              label=""
              name="agreeTerms"
              checked={agreeTerms}
              type="checkbox"
              id="agreeTerms"
              className="ml-lg-2 font-x-medium"
              onChange={handleAgreeTermsChange}
            /> 
            <small style={{marginLeft: '-10px'}}>I hereby agree to these terms of participating in this contest</small>
            </div>
            <div
              className="d-flex justify-content-center align-content-center py-4"
              style={{ borderBottom: '1px solid #ccc' }}
            >
              <Button
                className="border-0 bgPrimary text-white rounded-pill"
                disabled={!isFormValid || submissionInProgress}
                onClick={handleSubmitEntry}
                title={isFormValid ? '' : 'Please fill mandatory fields'}
              >
                Submit Entry
              </Button>
          </div>
        </div>
      </div>
      {successModal && (
        <Modal show={successModal} className="border-0" backdrop="static">
          <Modal.Body className="text-center pt-5">
            <p>
              Thank you. Your entry has been registered. <br />
              Please quote your phone number and the category for all future communication with us.
            </p>
          </Modal.Body>

          <Modal.Footer className="d-fflex justify-content-center align-items-center py-0 pb-3 border-0">
            <Button variant="secondary" size="sm" onClick={() => {toggleSuccessModal(false);props.history.push('/')}} className="bg-success px-4">
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Qualified;
