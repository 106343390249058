import instagram from '../../assets/img/icon_Instagram.png';
import facebook from '../../assets/img/icon_FB.png';
import email from '../../assets/img/icon_Email.png';

export default [
    {
        link: 'https://m.facebook.com/MisaalConcert/videos/1295503400792651/',
        imgPath: facebook,
        altText: 'Facebook'
    },
    {
        link: 'https://www.instagram.com/misaal.concert/?hl=en',
        imgPath: instagram,
        altText: 'Instagram'
    },
    {
        link: 'mailto:misaal.concert@gmail.com',
        imgPath: email,
        altText: 'Email'
    }
    
]