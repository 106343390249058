import React, { useContext, useState, useReducer, useEffect } from 'react';
import { Modal, Button, Form, InputGroup, ProgressBar, Alert } from 'react-bootstrap';
import axios from 'axios';

import Dropzone from 'react-dropzone';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { AppContext } from '../../screens/ContextProvider';
import statesArray from '../../helpers/statesArray';
import { getAgeByDob, getRevisedDate } from '../../helpers/dateHelpers';

const initState = {
  name: '',
  fatherName: '',
  dateOfBirth: '',
  age: 0,
  address: '',
  city: '',
  state: '',
  pinCode: '',
  emailId: '',
  categoryId: 1,
  phoneNumber: '',
  alternatePhoneNumber: '',
  whatsAppNumber: '',
  whatsApp1: false,
  whatsApp2: false
};

const initAlertState = {
  showAlert: false,
  alertType: 'success',
  alertText: ''
};

const inituploadError = {
  showUploadAlert: false,
  uploadAlertText: ''
};

// min 6 years
const maxDate = new Date(getRevisedDate(6));

// max 18 years
const minDate = new Date(getRevisedDate(18));

const RegisterModal = ({ onSuccess }) => {
  const {
    registerModal: { show, applyCategory },
    categories = [],
    toggleRegisterModal
  } = useContext(AppContext);

  const [formState, setFormState] = useReducer((state, next) => ({ ...state, ...next }), initState);

  const {
    name,
    fatherName,
    dateOfBirth,
    age,
    address,
    city,
    state,
    pinCode,
    emailId,
    categoryId,
    phoneNumber,
    alternatePhoneNumber,
    whatsApp1,
    whatsApp2
  } = formState;

  const [videoFile, setVideoFile] = useState(null);
  const [fileInfo, setFileInfo] = useState({
    fileName: '',
    fileSize: 0
  });
  const [progress, setProgress] = useState(0);
  const { fileName, fileSize } = fileInfo;

  const [isFormValid, setIsFormValid] = useState(false);
  const [submissionInProgress, setSubmissionInProgress] = useState(false);

  const [alertState, setAlertState] = useReducer((state, next) => ({ ...state, ...next }), initAlertState);
  const { showAlert, alertType, alertText } = alertState;

  const [uploadErrorState, setUploadErrorState] = useReducer((state, next) => ({ ...state, ...next }), inituploadError);
  const { showUploadAlert, uploadAlertText } = uploadErrorState;

  useEffect(() => {
    setFormState({ categoryId: applyCategory });
  }, [applyCategory]);

  useEffect(() => {
    // Reset Modal State
    setFormState({ ...initState, categoryId: applyCategory });
    setVideoFile(null);
    setFileInfo({
      fileName: '',
      fileSize: 0
    });
    setIsFormValid(false);
    setUploadErrorState(inituploadError);
    setProgress(0);
    setAlertState(initAlertState);
  }, [show]);

  const validateForm = () => {
    if (
      !name ||
      !fatherName ||
      !dateOfBirth ||
      !emailId ||
      !address ||
      !city ||
      !state ||
      !pinCode ||
      !phoneNumber ||
      !categoryId ||
      !videoFile
    ) {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  };

  useEffect(() => {
    validateForm();
  }, [formState, videoFile]);

  /* Change Handlers */

  const handleDrop = files => {
    // console.log(files);
    if (files.length > 0) {
      setVideoFile(files[0]);
      setFileInfo({
        fileName: files[0].name,
        fileSize: parseInt(files[0].size / 1024 / 1024)
      });
      setAlertState(initAlertState);
    } else {
      setAlertState({
        showAlert: true,
        alertType: 'danger',
        alertText: 'Only .mp4, .avi, .wmv and .mov files accepted. File size should not exceed 30MB.'
      });
      setVideoFile(null);
      setFileInfo({
        fileName: '',
        fileSize: 0
      });
    }
  };

  const handleDateChange = dateOfBirth => {
    if (dateOfBirth) {
      const age = getAgeByDob(dateOfBirth);
      if (age && age > 5 && age < 19) {
        setFormState({ dateOfBirth: new Date(dateOfBirth), age });
      }
    }
  };

  const handleDateBlur = ({ target: { value: dob } }) => {
    if (dob) {
      const dateOfBirth = new Date(dob);
      const age = getAgeByDob(dateOfBirth);
      if (age && age > 5 && age < 19) {
        setFormState({ dateOfBirth, age });
      } else {
        setFormState({ dateOfBirth: '', age: 0 });
      }
    } else {
      setFormState({ dateOfBirth: '', age: 0 });
    }
  };

  const handleNameChange = ({ target: { name, value } }) => {
    setFormState({ [name]: value });
  };

  const handleNameBlur = ({ target: { name, value } }) => {
    setFormState({ [name]: `${value}`.trim() });
  };

  const handleNumberChange = ({ target: { name, value } }) => {
    if (value === '' || !isNaN(value)) {
      setFormState({ [name]: +value || '' });
    }
  };

  const handleNumberBlur = ({ target: { name, value } }) => {
    if (value.trim() === '' || !isNaN(value.trim())) {
      const trimmedVal = +value.trim() || '';
      setFormState({ [name]: trimmedVal });
    }
  };

  // const handlePasswordChange = ({ target: { name, value = '' } }) => {
  //   if (value === '' || (!isNaN(value) && value.length <= 4)) {
  //     setFormState({ [name]: +value || '' });
  //   }
  // };

  // const handlePasswordBlur = ({ target: { name, value = '' } }) => {
  //   if ((value.trim() === '' || !isNaN(value.trim())) && value.trim().length <= 4) {
  //     const trimmedVal = +value.trim() || '';
  //     setFormState({ [name]: trimmedVal });
  //   }
  // };

  const handlePhoneChange = ({ target: { name, value } }) => {
    if (value === '' || !isNaN(value)) {
      setFormState({ [name]: value });
    }
  };

  const handlePhoneBlur = ({ target: { name, value } }) => {
    if (value.trim() === '' || !isNaN(value.trim())) {
      const trimmedVal = value.trim() || '';
      setFormState({
        [name]: trimmedVal
      });
    }
  };

  const handleEmailChange = ({ target: { name, value } }) => {
    setFormState({ [name]: value });
  };

  const handleEmailBlur = ({ target: { name, value } }) => {
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegEx.test(value)) {
      setFormState({ [name]: value });
    } else {
      setFormState({ [name]: '' });
    }
  };

  const handleCheckboxChange = ({ target: { name, checked } }) => {
    if (name.includes('1')) {
      setFormState({
        whatsApp1: checked,
        whatsApp2: whatsApp2 && !checked,
        whatsAppNumber: checked ? phoneNumber : whatsApp2 && !checked ? alternatePhoneNumber : ''
      });
    } else {
      setFormState({
        whatsApp1: whatsApp1 && !checked,
        whatsApp2: checked,
        whatsAppNumber: checked ? alternatePhoneNumber : whatsApp1 && !checked ? alternatePhoneNumber : ''
      });
    }
  };

  const handleRadioChange = ({ target: { name, value } }) => {
    setFormState({ [name]: +value });
  };

  const uploadVideoToDb = async url => {
    try {
      setSubmissionInProgress(true);
      const contentType = videoFile.type;
      const res = await axios.put(url, videoFile, {
        headers: {
          'Content-Type': contentType
        },
        onUploadProgress: progressEvent => {
          const { loaded, total } = progressEvent;
          setSubmissionInProgress(true);
          setProgress(parseInt(Math.round((loaded * 100) / total)));
        }
      });
      // console.log(res.data);
      onSuccess();
      setSubmissionInProgress(false);
    } catch (error) {
      setSubmissionInProgress(false);
      // console.log(error.response);
      if (error.response) {
        const { data } = error.response;
        if (typeof data === 'string') {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: 'Server Error, Please Try Again!'
          });
        }
      } else {
        setUploadErrorState({
          showUploadAlert: true,
          uploadAlertText: 'Network Error, Please Try Again!'
        });
      }
    }
  };

  const registerUser = async data => {
    try {
      setUploadErrorState({
        showUploadAlert: false,
        uploadAlertText: ''
      });
      setSubmissionInProgress(true);
      const res = await axios.post(`${process.env.REACT_APP_BASE_URL}api/participants/one-pass-upload`, data);
      // console.log({ res });
      const { content, registration } = res.data;
      if (registration.status) {
        // registered success
        if (content && content.status) {
          if (content.presignedUrl) {
            uploadVideoToDb(content.presignedUrl);
            return;
          }
        } else {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: content.message
          });
        }
      } else {
        setUploadErrorState({
          showUploadAlert: true,
          uploadAlertText: registration.message
        });
      }
      setSubmissionInProgress(false);
    } catch (error) {
      setSubmissionInProgress(false);
      // console.log(error.response);
      if (error.response) {
        const { data } = error.response;
        if (typeof data === 'string') {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: 'Server Error, Please Try Again'
          });
        } else if (!data.registration.status) {
          setUploadErrorState({
            showUploadAlert: true,
            uploadAlertText: data.registration.message
          });
        }
      } else {
        setUploadErrorState({
          showUploadAlert: true,
          uploadAlertText: 'Network Error, Please Try Again!'
        });
      }
    }
  };

  const handleSubmitEntry = async () => {
    const omitKeys = ['age', 'categoryId', 'whatsApp1', 'whatsApp2'];
    const requestBody = {
      content: {
        categoryId,
        fileName
      },
      registration: {
        loginId: phoneNumber
      }
    };
    Object.keys(formState).forEach(key => {
      if (!omitKeys.includes(key)) {
        requestBody.registration[key] = formState[key];
      }
    });
    const [, month, date, year] = new Date(dateOfBirth).toDateString().split(' ');
    const formattedDOB = `${date}-${month}-${year}`;
    requestBody.registration['dateOfBirth'] = formattedDOB;
    // console.log({ requestBody });
    registerUser(requestBody);
  };

  return (
    <>
      <Modal
        show={show}
        size="lg"
        centered
        className="border-0"
        onHide={toggleRegisterModal}
        scrollable
        backdrop="static"
      >
        <Modal.Header closeButton style={{ position: 'relative' }}>
          <Modal.Title className="h6 mx-auto" style={{ paddingBottom: '0.3rem' }}>
            SUBMIT YOUR ENTRY
          </Modal.Title>
          {/* <small className="text-center d-block" style={{ position: 'absolute', bottom: '3px', left: '0', right: '0' }}>
            You can submit only one entry across categories
          </small> */}
        </Modal.Header>

        <Modal.Body id="register-content" className="d-flex align-content-center flex-column flex-lg-row-reverse">
          <section id="register-form-container" className="flex-grow-1 mx-lg-4">
            <div className="container">
              <p className="form-title font-large">Participant Information</p>
              <Form id="register-form">
                <Form.Control
                  type="text"
                  value={name}
                  name="name"
                  placeholder="PARTICIPANT'S NAME*"
                  className="bg-form-control border-0"
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                />
                <Form.Control
                  type="text"
                  value={fatherName}
                  name="fatherName"
                  placeholder="FATHER'S/GUARDIAN'S NAME*"
                  className="bg-form-control border-0"
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                />
                <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                  <ReactDatePicker
                    dateFormat={['MM/dd/yyyy', 'MM-dd-yyyy']}
                    placeholderText="BIRTH DATE*  (mm/dd/yyyy)"
                    selected={dateOfBirth}
                    onChange={handleDateChange}
                    onBlur={handleDateBlur}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    minDate={minDate}
                    maxDate={maxDate}
                    withPortal
                    className="w-100 bg-form-control mb-0 border-0 p-1 font-x-medium date-field"
                    wrapperClassName="w-60"
                  />
                  <Form.Text className="w-30 font-medium">Age: {age} Years</Form.Text>
                </Form.Group>
                {(age < 6 || age > 18) && (
                  <Form.Text className="text-secondary ml-1 font-medium">
                    Select Valid Date (mm/dd/yyyy) & Age must be between 6-18 years
                  </Form.Text>
                )}
                <Form.Control
                  type="text"
                  value={address}
                  name="address"
                  placeholder="ADDRESS*"
                  className="bg-form-control border-0"
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                />
                <Form.Control
                  type="text"
                  value={city}
                  name="city"
                  placeholder="CITY*"
                  className="bg-form-control border-0"
                  onChange={handleNameChange}
                  onBlur={handleNameBlur}
                />
                <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                  <Form.Control
                    as="select"
                    value={state}
                    name="state"
                    onChange={handleNameChange}
                    className="bg-form-control border-0 w-60"
                  >
                    <option value="">STATE*</option>
                    {statesArray.map(({ name, code }) => (
                      <option key={code} value={code}>
                        {name}
                      </option>
                    ))}
                  </Form.Control>
                  <Form.Control
                    type="text"
                    value={pinCode}
                    name="pinCode"
                    maxLength="6"
                    placeholder="PINCODE*"
                    className="bg-form-control border-0 w-35"
                    onChange={handleNumberChange}
                    onBlur={handleNumberBlur}
                  />
                </Form.Group>
                <Form.Control
                  type="email"
                  name="emailId"
                  value={emailId}
                  placeholder="EMAIL ID*"
                  className="bg-form-control border-0"
                  onChange={handleEmailChange}
                  onBlur={handleEmailBlur}
                />
                <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="phone-code1" className="border-0 font-x-medium">
                      +91
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="tel"
                    value={phoneNumber}
                    name="phoneNumber"
                    maxLength="10"
                    placeholder="PHONE 1*"
                    className="bg-form-control border-0 w-50"
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                  />
                  <Form.Check
                    inline
                    label="WhatsApp"
                    name="whatsApp1"
                    checked={whatsApp1}
                    type="checkbox"
                    id="whatsApp-1"
                    className="w-30 ml-lg-2 font-x-medium"
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
                <Form.Group className="d-flex justify-content-between align-items-center mb-0">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="phone-code2" className="border-0 font-x-medium">
                      +91
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="tel"
                    value={alternatePhoneNumber}
                    name="alternatePhoneNumber"
                    maxLength="10"
                    placeholder="PHONE 2"
                    className="bg-form-control border-0 w-50"
                    onChange={handlePhoneChange}
                    onBlur={handlePhoneBlur}
                  />
                  <Form.Check
                    inline
                    label="WhatsApp"
                    name="whatsApp2"
                    checked={whatsApp2}
                    type="checkbox"
                    id="whatsApp-2"
                    className="w-30 ml-lg-2 font-x-medium"
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>
              </Form>
            </div>
          </section>
          <section className="divider"></section>
          <section id="file-upload" className="flex-grow-1 mt-5 mt-lg-0 mx-lg-4">
            <div className="container">
              <section id="form-category" className="mb-5">
                <p className="form-title font-large">Select Category*</p>
                {categories.map(({ name, categoryId: catId }) => (
                  <Form.Check
                    type="radio"
                    key={catId}
                    label={name}
                    value={catId}
                    checked={categoryId === catId}
                    name="categoryId"
                    onChange={handleRadioChange}
                    id={`category${catId}`}
                    className="col-sm-12"
                  />
                ))}
              </section>
              {/* <section id="password" className="my-4">
                <Form.Control
                  type="password"
                  value={password}
                  name="password"
                  placeholder="PASSWORD*"
                  className="bg-form-control border-0"
                  onChange={handlePasswordChange}
                  onBlur={handlePasswordBlur}
                />
                <Form.Text className="text-secondary ml-1 font-medium">Password must be 4 digit</Form.Text>
              </section> */}
              <section id="video-upload" className="mt-5">
                <p className="form-title font-large">Your Video*</p>
                {showAlert && (
                  <Alert variant={alertType} className="pr-0">
                    <small className="p-0 m-0">{alertText}</small>
                  </Alert>
                )}
                <Dropzone
                  onDrop={handleDrop}
                  accept="video/mp4,video/webm,video/quicktime,video/avi,.flv,.mwv"
                  maxSize={50*1024*1024}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      {videoFile && fileName && fileSize ? (
                        <div className="fileUploadInfo">
                          <span>{fileName}</span>
                          <span className="ml-1">{fileSize}Mb</span>
                          <ProgressBar
                            variant="success"
                            now={progress}
                            label={`${progress}%`}
                            className="mt-4 mb-3 progressBar"
                          />
                        </div>
                      ) : (
                        <>
                          <p>Drag to upload you video</p>
                          <p className="my-2">Or</p>
                          <Button className="border-0 bgPrimary text-white rounded-pill">SELECT FILE</Button>
                        </>
                      )}
                    </div>
                  )}
                </Dropzone>
                <div className="text-center">
                  <small>Only .mp4, .avi, .wmv and .mov files accepted. File size should not exceed 30MB.</small>
                </div>
              </section>
            </div>
          </section>
        </Modal.Body>

        {showUploadAlert && (
          <Alert
            variant="danger"
            onClose={() => {
              setUploadErrorState(inituploadError);
              setProgress(0);
            }}
            dismissible
            className="text-center mb-0 bg-danger text-light"
            id="uploadError"
          >
            <small>{uploadAlertText}</small>
          </Alert>
        )}

        <Modal.Footer className="d-flex justify-content-center align-content-center">
          <Button
            className="border-0 bgPrimary text-white rounded-pill"
            disabled={!isFormValid || submissionInProgress}
            onClick={handleSubmitEntry}
            title={isFormValid ? '' : 'Please fill mandatory fields'}
          >
            Submit Entry
          </Button>
          <Button variant="light" className="text-primary ml-3" onClick={toggleRegisterModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegisterModal;
