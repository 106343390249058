import React from 'react';
import logo from '../../assets/img/Misaal_logo.png';

const About = () => {
  return (
    <>    
    <div className="container-fluid lgbg"><br /><br />
        <div className="container">
        <h1>About <strong>Misaal</strong></h1><br /><br />     
        <div className="row">
          <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-12">            
            <h4>Our Vision</h4>
            <p>Our vision is to create a platform for the Indian youth across the globe to fulfill their dreams of a brighter future and provide support to the millions of people who are suffering.</p> 
            <h6>Each one for everyone - All of us for humanity</h6>
            <br />            
            <h4>Our Mission</h4>
            <p>Misaal is born with the mission of touching the lives of the millions of people who are facing the extreme agony of poverty and unemployment, aggravated by the current crisis. Youth will showcase their talent and in turn serve the society with the proceeds of the talent show.</p>
            <p>All proceeds of the event will go to Magic Bus, our NGO partner</p>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12">
            <div className="logo-placeholder">
            <img alt="Misaal - Young India Inspires" title="Misaal - Young India Inspires" className="about-misaal-logo" src={logo} />
            </div>
          </div>
        </div> 
        </div>     
    </div>
    </>
  );
};

export default About;
