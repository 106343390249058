import React from 'react';

const Privacy = () => {
  return (
    <>
        <br />
        <div className="container">
          <h1 className="th-title">Privacy <strong>Policy</strong></h1>
          <br />


<h4>Scope and Application</h4> 
<ul className="ul-list">
<li>This privacy policy is an electronic record in the form of an electronic contract drafted under the (Indian) Information Technology Act, 2000, the Information Technology (Reasonable security practices and procedures and sensitive personal data of information) Rules, 2011, as amended from time to time ("Privacy Policy").</li>
<li>The terms 'We/Us/Our' shall refer to Misaal Talent Hunt, having our offices at and the terms 'You/Your/Yourself' shall refer to the users/ members/ and any other persons accessing or seeking to use the Platform (as defined below).</li>
<li>This Privacy Policy is a legally binding document between you and us which governs the usage, collection, protection and sharing of any Information that you provide us, when you access or our website and/ or our mobile applications and/ or otherwise interact with us through any electronic medium (the "Platform").</li>
<li>This Privacy Policy shall be read together with the other policies published by us on the Platform, including without limitation the Terms of Use (together the "Policies"). By accessing or using the Platform, you indicate that you understand, agree and consent to the terms and conditions contained in the Privacy Policy. If you do not agree with the terms and conditions of this Privacy Policy, please do not use this Platform.</li>
<li>Please note that we are not responsible for, and this Privacy Policy does not govern Information provided other than through the Platform.</li>
</ul>
<h4>Commitment</h4>
<ul className="ul-list">
<li>We are committed to ensure that your privacy is protected to all possible, reasonable and commercial extent, as your privacy and data protection is of the utmost importance to us.</li>

<li>We gather certain types of Information about you in order to provide, maintain and improve the facilities rendered to you, and hence, we strongly urge you to fully understand the terms and conditions of the Privacy Policy surrounding the capture and use of such Information.</li>
</ul>
<h4>Collection of Information</h4>
<ul className="ul-list">
<li>For the purposes of rendering the services and facilities through the Platform, we may collect your Information from various channels, including, but not limited to, voluntary submission of information from your end in furtherance of the access or usage of the Platform, through requests initiated by you on the Platform and through other electronic communications.</li>
<li>Please note that we are based in the Republic of India, and our servers are also based in the Republic of India. Your Information may be transferred to our servers located in the Republic of India. Please be aware that we are governed by the (Indian) Information Technology Act, 2000; however, even though it may or may not accord the same degree of data protection as your home jurisdiction, our collection, storage and usage of Information will continue to be governed by this Privacy Policy. We will always endeavor to protection your Information.</li>
</ul>
<h4>Types of Information collected by the Us ("Information")</h4>

<h5>Personally Identifiable Information (PII)</h5>
<ul className="ul-list">
<li>PII, as used in information security and privacy laws, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context.</li>

<li>We may collect and retain certain PII including without limitation your name, age, gender, telephone number, school name, identification card, mailing address, including but not limited to permanent and current residential addresses, email address, etc. These PII are essential for your use of our facilities and Platform. In case of a minor, we may be collecting PII of both the minor participant/ user as well as that of the parents/ legal guardians, as applicable, which are essential for the Misaal Talent Hunt Program.</li>

<li>We also collect information exchanged by you with us in the form of written communication, responses to emails, feedback provided, participation in discussions, etc. is handled with confidentiality and will be available for our exclusive use.</li>

<li>You have the right to request to discontinue our use of your PII. To withdraw your consent to our collection and processing of your information in future, You may do so by closing your account with us/ specifically informing us that you are withdrawing your consent towards our use of your PII.</li>
</ul>
<h5>Non-Personal Information (NPI)</h5>
<ul className="ul-list">
<li>We also collect information in a form that does not permit direct association with any specific individual including without limitation the information of the browser you use, usage details and identifying technologies ("NPI"). NPI includes without limitation referring/ exit pages, anonymous usage data, and URLs, platform types, number of clicks, etc.). We request you to note that when you access the Platform or receive emails or any communication from us, we along with its affiliated companies and permitted agents, may use cookies and/or pixel tags to collect information and store your online preferences.</li>
<li>If we do combine NPI with PII, then the combined information will be treated as PII for as long as it remains combined.</li>
<li>We may also collect other non-specific information to provide you better access to the facilities and Platform each time such as preferences and interests and other non-personal details names shared via the Platform, any application form or email or any other medium. We may also store and publish such information to assist us in making the Platform better and easier to use.</li>
</ul>
<h5>Information Usage and Sharing</h5>
<ul className="ul-list">
<li>We have a legitimate interest in using your personal data only for journalistic, artistic and literary purposes and for the performance of your agreement with us and fulfilling our legal obligations.</li>
<li>Our primary goal in collecting the Information is for us to render the facilities and related services to you, in connection with the Misaal Talent Hunt Program. Additionally, we will also be using the Information for our internal operational purposes, such as providing, maintaining, evaluating, and improving the Platform, and also for providing any service-related support. We would also be sharing the information with others in the manner provided in this Privacy Policy.</li>
<li>Please note that we do not and will not trade or sell your PII in any manner. Where we process your personal information for our legitimate interests, we will carefully consider any impact on you and will not use your personal information where such impact overrides our interests.</li>
<li>We are both a data controller and a data processor.</li>
<li>We may share Your PII with third parties only in the following limited circumstances:
<ol className="ul-list">
<li>With the production team/ crew/ employees/ judges/ experts in the course of the Misaal Talent Hunt Program</li>
<li>Where we have a good faith belief that access, use, preservation or disclosure of such PII is reasonably necessary to: (i) satisfy any applicable law, regulation, legal process or enforceable governmental request including to law enforcement and in response to a court order, (ii) detect, prevent, or otherwise address fraud, technical or security issues, (iii) enforce applicable Policies of the Platform, which may be further extended to investigation of potential violations thereof, or (iv) protect against harm to our rights, safety or property, our users or the public as required or permitted by law;</li>
<li>To protect ourselves against third-party claims;</li>
<li>We may disclose/ share information to those third-parties who support our business, such as providing technical services, analyzing how our services are used, measuring the effectiveness, providing customer support and business support services, and such information will be used for the limited purposes of such services received by us.</li>
</ol></li>
<li>We may share NPI with interested third parties to help them understand the usage patterns and analyses for certain facilities and services and in connection with the access and use of the Platform. Any NPI and data and analyses arising therefrom may be shared by us to its existing and potential partners, advertisers, investors, and others.</li>
<li>Specifically, videos and performances from the Misaal Talent Hunt Program will be filmed and may be used in broadcasts on media. For the users selected to be part of any broadcast, we will be required to retain certain PII relating to such user's contribution in perpetuity. We will retain other PII (such as your contact details, and any relevant information collected in connection with the participation, for up to 7 years in line with our data retention schedule. If the users are not selected to be part of the program, we will not deal in your PII no later than 12 months from the end of the selection process. The copy of a minor's identification card will be destroyed once the entry has been validated, and in any event, within a period of 6 months thereof. If a user wishes to withdraw his/ her entry at any point, we will delete the data from our system and/or destroy your entry, as relevant.</li>
</ul>
<h4>Links to/ from Third-Party Websites</h4>
<ul className="ul-list">
<li>The Platform may contain links to third-party websites, products, and services. Information collected by third parties, which may include without limitation information such as location data or contact details, is governed by the privacy practices undertaken by such third parties. We encourage you to learn about the privacy practices of those third parties. We expressly assert that we cannot be responsible for these practices.</li>
<li>We may have presence on social networking websites including but not limited to LinkedIn, Facebook, Twitter, Instagram, YouTube and blogs which are promotional and business initiatives to attract, engage and connect to a larger group of people. The domain links contained therein may either direct you to our Platform or request your participation by way of feedback, suggestions, etc. We, in this regard, fully disclaims any liability(ies) or claim(s), which may arise by use/misuse of your feedback, suggestions, views, etc. on any of the aforementioned networking websites or blogs, by any third party whether or not known to us.</li>
</ul>
<h4>Information Security &amp; Retention</h4>
<ul className="ul-list">
<li>We take precautions including without limitation administrative, technical and physical measures to safeguard your personal information against loss, misuse or theft, as well as against destruction, alteration, disclosure and unauthorized access. Specifically, while we will ensure our best efforts to protect your Information in line with commercially reasonable efforts and general industry standards; however, we do not represent, warrant, or guarantee that your Information will be protected against unauthorized access, loss, misuse, or alterations beyond our reasonable control.</li>
<li>Notwithstanding anything contained in this Policy or elsewhere, we shall not be held responsible for any loss, damage or misuse of your PII, if such loss, damage or misuse is attributable to a force majeure event (as provided in our terms of use).</li>
<li>You acknowledge and agree that we reserve the right to remove or edit content, refuse service/ facilities, terminate accounts, or cancel plans or orders at our sole discretion.</li>
<li>Our Platform may provide links to any other website or locations for your convenience, but the provision of such links does not signify our endorsement of such other website or location or its contents. We have no control over, does not review, and cannot be responsible for these outside websites or their content. Please be aware that the terms of this Privacy Policy do not apply to these outside websites or location or its contents.</li>
</ul>
<h4>Grievances</h4>
<ul className="ul-list">
<li>In case of breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorized disclosure of, or access to, personal data transmitted, stored or otherwise processed, we have the mechanisms and policies in place in order to identify it and assess it promptly.  Depending on the outcome of our assessment, we will make the requisite notifications to the supervisory authorities and communications to the affected data subjects, which might include you.</li>
<li>In accordance with the legal requirements, the name and contact details of the Grievance Officer who can be contacted with respect to any complaints or concerns including those pertaining to breach of this Privacy Policy and other Polices are published as under:
<ol className="ul-list">
<li>Grievance Officer Name</li>
<li>Email address</li>
<li>Phone number</li>
<li>Address</li>
</ol></li>
<li>The Grievance Officer can be contacted between 10am to 5pm from Monday to Friday except on public holidays.</li>
</ul>
<h4>Governing Law</h4>
<ul className="ul-list">
<li>We are incorporated in, and based out of the Republic of India, and we are duty bound to abide by Indian laws. We may not have complied with some privacy laws of other countries and further represent to be unaware of such other legal requirements.</li>
<li>Further, any disputes regarding this Privacy Policy shall be subject to the exclusive jurisdiction of the courts of Bengaluru, India.</li>
</ul>
<h4>Questions and Contact Information</h4>
<ul className="ul-list">
<li>If you would like to access, correct, amend or delete any of your Information shared with us, please register a complaint, or if you want more Information about this Privacy Policy, please contact us. We shall respond to and address all reasonable concerns or inquiries in a reasonable amount of time.</li>
</ul>
<h4>Updates to the Privacy Policy</h4>
<ul className="ul-list">
<li>We reserve the right to modify, amend, suspend, terminate or replace this Privacy Policy from time to time within our sole discretion. We will post any changes made to the Privacy Policy on the Platform without prior notice to you. Such changes will take effect immediately upon their posting on the Platform.</li>
<li>We encourage you to review the Privacy Policy frequently. By your continued use of the Platform, you consent to the terms of the revised/ updated Privacy Policy.</li>
</ul>
      </div>
    </>
  );
};

export default Privacy;
